import EmplyService from "services/Emply_service/emply.service";
import { createContext, useReducer } from "react";

export const getEmplyData = async () => {
  let emplyEmployee;
  let emplyMasterData;
  let object;
  

  try {
    emplyEmployee = await EmplyService.GetEmployeeByEmail();
    
    let department = emplyEmployee.departmentId;
    await EmplyService.GetMasterDataID(emplyEmployee[0]?.id, async (data) => {
      if (data !== undefined) {
        for (const e of data) {
          if (e.dataTypeId === "388621d0-278d-4e14-b627-4d49e523ccb0") {
            for (const r of e.relations) {
              if (r.id !== "5325422c-a407-4a3d-815d-7de27da9d027") {
                if (r.department !== null) {
                  department = r.department
                }
              }
            }
          }
        }
        emplyMasterData = data;
      }
    });
 

    object = {
      FirstName: "2d2bf7f2-a7d4-4369-a0a1-5a29f1196021",
      LastName: "50f68c58-e648-4c09-b51b-1dfefe2a61b6",
      Birthday: "20afabbf-2fa8-4321-9480-4feca9e1b6b2",
      Address: "ba557698-fcf2-4ca6-897b-5314474ba7b0",
      ZIP: "060e2d63-0416-4d2b-b454-547b25b7be8c",
      City: "85e59d17-8afc-4ee4-93ca-54dba960c892",
      Phone: "f1930117-4642-4ee7-8b37-96d73c6b2cac",
      CompanyPhone: "f1930117-4642-4ee7-8b37-96d73c6b2cac",
      PrivateEmail: "f2f7bd59-a656-4d78-a584-975dbae84a7d",
      KeyCard: "3f605ca9-3892-4f5d-bede-ee047fb79d26",
      KeyCardAccNo: "0e3b3bb2-990e-4369-9839-130878532171",
      RegNo: "b73a0c32-769f-4d3d-be15-79632f78d430",
      AccNo: "ad0d3c85-e23b-45fa-afdf-ac59b65c160b",
      CPR: "1e65c703-3d75-428b-9e04-b54e9b3f16ff"
    };
    let editable = await EmplyService.RetrieveEditable();
    let masterdataOptions = await EmplyService.GetMasterDataForm()

    return {
      MasterData: emplyMasterData,
      Changable: object,
      Department: department,
      Editable: editable,
      MasterDataOptions: masterdataOptions
    };
  } catch (err) {
    console.log(err)
    throw err;
  }
};

let initialState = {};
export const EmplyState = createContext(initialState);
export const EmplyProvider = ({ Emply, children }) => {
  const Reducer = (state, action) => {
    const name = Object.keys(action);
    const values = Object.values(action);
    return { ...state, [name]: values[0] };
  };
  const [state, dispatch] = useReducer(Reducer, Emply);

  return <EmplyState.Provider value={[state, dispatch]}>{children}</EmplyState.Provider>;
};
