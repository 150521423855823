import http from "../Common_service/rest_http_common";
import moment from "moment"
import { TokenService } from "services";
import salaryService from "services/REST_service/salary.service";
import ConverterClass from "App/Functions/converterClass.js";

class ScheduleDataService {
  getAll() {
    return http.get("/Schedule");
  }

  get(id) {
    return http.get(`/Schedule/${id}`);
  }

  async ExportReport(param) {
    try {
      const data = await http.get(`/Schedule/start/${param.start}/end/${param.end}`);
      if (data.data.length === 0) {
        return data.data;
      }
      return data.data;
    } catch (error) {
      return []
    }
  }
  async GetTransactions(Transaction_ID) {
    const data = await http.get(`/Schedule/transactions/id/${Transaction_ID}`);
    return data;
  }
  async GetSchedule(Transaction_ID) {
    const data = await http.get(`/Schedule/${Transaction_ID}`);
    return data;
  }

  async GetNexDaysOnSchedule(date) {
    const data = await http.get(`/Schedule/NextDays/${date}`);
    return await data.data;
  }
  async GetScheduleWithLimitAndOffset(param) {
    const data = await http.get(`/Schedule/start/${param.start}/limit/${param.limit}/offset/${param.offset}`);
    return await data.data;
  }
  async GetByDayAndUser(param) {
    const user = { UserUUID: param.UserUUID, EmployeeID: param.EmployeeID }
    const data = await http.get(`/Schedule/user/${JSON.stringify(user)}/start/${param.start}/end/${param.end}`);
    return data;
  }
  async GetBooked(param) {
    const data = await http.get(`/Schedule/start/${param.start}/end/${param.end}/tables`);
    return data;
  }

  async Bulkdelete(payload) {
    const data = await http.delete("/Schedule/delete/bulk", {
      data:
        payload
    });
    return data
  }
  async GetHoursForTheMonth(param) {
    const data = await http
      .get(`/Schedule/team/${param.team}/month/${param.start}/sum/hours`)
      .catch(function (error) {
        return error;
      });
    return data;
  }
  async ExportScheduleMonthlyByTeam(param) {
    try {
      const data = await http.get(`/Schedule/teams/${JSON.stringify(param.teamids)}/month/${JSON.stringify(param.month)}`);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
  async GetMonthlyHours(param, filter) {
    try {
      const data = await http.get(`/Schedule/month/${param}/sum/hours?filter=${JSON.stringify(filter)}`);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
  async GetMonthlyHoursByUserArray(param) {
    try {
      const data = await http.post(`/Schedule/users/month/sum/hours`, param);
      return data;
    } catch (error) {
      console.log(error);
    }
  }
  async GetScheduledHoursForUserPeriod(param) {
    try {
      const data = await http.get(`/Procedure/GetScheduledHoursForUserPeriod/${JSON.stringify(param)}`);
      return data;
    } catch (error) {
      console.log(error);
    }
  }

  async pingTrueFalse(param) {
    try {
      const data = await http.get(`/Schedule/user/${param.uuid}/start/${param.start}/end/${param.end}/ping`);
      return data;
    } catch (error) {
      return error;
    }
  }

  create(data) {
    return http.post("/Schedule", data);
  }

  async Import(param) {
    try {
      const data = await http.post(`/Schedule/import`, param);
      return data;
    } catch (error) {
      console.log(error)
      return error;
    }
  }

  async updateOrCreateTables(data) {
    const result = await http.post("/Schedule/tables", data);
    return result;
  }

  async getTables() {
    const result = await http.get("/Schedule/tables/all");
    return result;
  }

  async DeleteTable(id) {
    const result = await http.delete(`/Schedule/tables/${id}`);
    return result;
  }

  update(id, data) {
    return http.put(`/Schedule/${id}`, data);
  }

  delete(id) {
    return http.delete(`/Schedule/${id}`);
  }


  async RetrieveSchedules(CurrentPeriod, Selection, account) {

    if (CurrentPeriod?.id === undefined) {
      throw new Error("CurrentPeriod is undefined")
    }

    let EmployeeID;
    let UserUUID
    if (account === undefined) {
      try {
        UserUUID = TokenService.getUserID()
      } catch (error) {
        console.log(error)
      }
      try {
        EmployeeID = TokenService.getEmployeeData().id
      } catch (error) {
        console.log(error)
      }
    } else {
      EmployeeID = account.EmployeeID
      UserUUID = account.UserUUID
    }

    let startOfCalendarYear;
    let endOfCalendarYear;
    if (Selection) {
     startOfCalendarYear = moment(Selection).startOf("year").startOf("day").format();
     endOfCalendarYear = moment(Selection).endOf("year").endOf("day").format();
    } else {
      startOfCalendarYear = moment().startOf("year").startOf("day").format();
      endOfCalendarYear = moment().endOf("year").endOf("day").format();
    }
      

    const param2 = {
      start: startOfCalendarYear,
      end: endOfCalendarYear,
      UserUUID: UserUUID,
      EmployeeID: EmployeeID,
    };

    let Calendar = ConverterClass.getCalendarDates(moment());
    //? prepare array of objects of dates (as many objects there are weeks)

    let FirstDayOnUserSchedule = Calendar[0].days[0]
    let LastDayOnUserSchedule = Calendar[Calendar.length - 1].days[Calendar[Calendar.length - 1].days.length - 1]


    if (Selection) {
      Calendar = ConverterClass.getCalendarDates(Selection);
      //? prepare array of objects of dates (as many objects there are weeks)
      FirstDayOnUserSchedule = Calendar[0].days[0]
      LastDayOnUserSchedule = Calendar[Calendar.length - 1].days[Calendar[Calendar.length - 1].days.length - 1]
      // If the first day and last day exist in between param2.start and param2.end
      if (moment(FirstDayOnUserSchedule).isBetween(param2.start, param2.end) && moment(LastDayOnUserSchedule).isBetween(param2.start, param2.end)) {
      } else {
        // if neither exist
        if (!moment(FirstDayOnUserSchedule).isBetween(param2.start, param2.end) && !moment(LastDayOnUserSchedule).isBetween(param2.start, param2.end)) {
          param2.start = moment(FirstDayOnUserSchedule).startOf("day").format()
          param2.end = moment(FirstDayOnUserSchedule).endOf("day").format()
          // If either the first day or last day exist in between param2.start and param2.end
        } else if (moment(FirstDayOnUserSchedule).isBetween(param2.start, param2.end) || moment(LastDayOnUserSchedule).isBetween(param2.start, param2.end)) {
          // which one exists
          if (moment(FirstDayOnUserSchedule).isBetween(param2.start, param2.end)) {
            param2.end = moment(LastDayOnUserSchedule).endOf("day").format()
          } else {
            param2.start = moment(FirstDayOnUserSchedule).startOf("day").format()
          }
        } else {
          // which one doesnt exist
          if (moment(FirstDayOnUserSchedule).isBetween(param2.start, param2.end)) {
            param2.start = moment(FirstDayOnUserSchedule).startOf("day").format()
          }
          else {
            param2.end = moment(LastDayOnUserSchedule).endOf("day").format()
          }
        }
      }

    }

    // Retain fetching for for the Calendar year, but fetch extra days if the selection is outside the calendar year
    // if param2.start is after the end of the calendar year
    if (moment(param2.start).isAfter(endOfCalendarYear)) {
      param2.start = startOfCalendarYear
    }
    // if param2.end is before the start of the calendar year
    if (moment(param2.end).isBefore(startOfCalendarYear)) {
      param2.end = endOfCalendarYear
    }
    let scheduled = await this.GetByDayAndUser(param2);
    let start = moment(CurrentPeriod.Date_start).startOf("day").format();
    let end = moment(CurrentPeriod.Date_end).endOf("day").format();
    let ScheduledInPeriod = scheduled.data.filter((a) => { return moment(a.Date_start_time).isBetween(start, end) })
    let ScheduledInPeriodHours = ScheduledInPeriod.reduce((a, b) => a + parseFloat(b.Hours), 0);
    // Filter out the dates that are between FirstDayOnUserSchedule and LastDayOnUserSchedule
    let UserCalendarMonth = Calendar
    let UserScheduledInCalendarMonth = scheduled.data.filter((a) => { return moment(a.Date_start_time).isBetween(FirstDayOnUserSchedule, LastDayOnUserSchedule) })
    let MonthlyScheduledHours = [];
    scheduled.data.forEach(function (a) {
      const name = moment(a.Date_start_time).format("MMMM");
      if (!this[name]) {
        this[name] = {
          Month: name,
          Hours: 0,
        };
        MonthlyScheduledHours.push(this[name]);
      }
      this[name].Hours = parseFloat(this[name].Hours) + parseFloat(a.Hours);
    }, Object.create(null));
    return { MonthlyScheduledHours, ScheduledInPeriodHours, ScheduledInPeriod, UserCalendarMonth, UserScheduledInCalendarMonth }
  }

}

export default new ScheduleDataService();

