// React imports
import React, { useState, useContext, useEffect, useCallback } from "react";
import PropTypes from "prop-types";

// Store
import { GlobalState } from "context/store.js";

// MUI imports
import { Button, Stack } from "@mui/material";
import TextField from "@mui/material/TextField";
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker'
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Modal from "@mui/material/Modal";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { InputLabel, MenuItem, Select } from "@mui/material";

// Microsoft imports
import { Person, PersonViewType } from "@microsoft/mgt-react";
import { PersonWithDetails } from "App/Views/Components/Microsoft_MGT/MGTComponents";

// Third party libraries imports
import Chartist from "chartist";
import ChartistGraph from "react-chartist";
import orderBy from "lodash/orderBy";
import moment from "moment";
import SimpleBar from "simplebar-react";

// Custom components imports
import CustomTable from "App/Views/Components/Material_ui/Table/Table";
import ChooseTableModal from "App/Views/Components/Modals/chooseTable";

// Services imports
import bankService from "services/REST_service/bank.service.js";
import HoursDataService from "services/REST_service/hours.service";
import ScheduleDataService from "services/REST_service/schedule.service";
import salaryService from "services/REST_service/salary.service.js";
import TokenService from "services/REST_service/token.service";

// Functions imports
import converterClass from "App/Functions/converterClass.js";
import Functions from "App/Functions/functions";
import useWindowSize from "App/Functions/useWindowSize";

// Styles imports
import { makeStyles } from "@material-ui/styles";
import styles from "App/Views/Components/classes";
import "simplebar/dist/simplebar.min.css";



const useStyles = makeStyles(styles);

export function ControlledRadioButtonsGroup(props) {
  const size = useWindowSize();
  const [value, setValue] = useState("Home Office");

  const handleChange = (event) => {
    setValue(event.target.value);
    if (event.target.value === "Office") {
      props.officeClicked(true, event.target.value);
    } else {
      props.officeClicked(false, event.target.value);
    }
  };
  useEffect(() => {
    setValue(props.currentState ? props.currentState : "Home Office")
  }, [props.currentState])

  return (
    <FormControl
      sx={{
        padding: size.width < 500 ? "1rem" : "0rem", marginTop: "1rem", display: "flex", flexDirection: "column"
      }}
      style={{ width: "100%" }}
      component="fieldset"
    >
      <RadioGroup
        aria-label="Location"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={handleChange}
      >
        <FormLabel component="legend">Location</FormLabel>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            maxWidth: "90%",
            flexWrap: "wrap",
          }}
        >
          <input type="hidden" name="Location" value={value} />

          <FormControlLabel
            value={"Office"}
            control={<Radio onClick={() => props.officeClicked(true)} />}
            label={"Office"}
          />
          <FormControlLabel
            value={"Home Office"}
            control={<Radio onClick={() => props.officeClicked(false)} />}
            label={"Home Office"}
          />

          {props.where === undefined &&
            <>
              <FormControlLabel
                value={"Business Trip"}
                control={<Radio onClick={() => props.officeClicked(false)} />}
                label={"Business Trip"}
              />
              <FormControlLabel
                value={"Vacation"}
                control={<Radio onClick={() => props.officeClicked(false)} />}
                label={"Vacation"}
              />
              <FormControlLabel
                value={"Office reserved"}
                control={<Radio onClick={() => props.officeClicked(false)} />}
                label={"Office reserved"}
              />
            </>
          }

        </div>
      </RadioGroup>
    </FormControl>
  );
}
export function TableBooking(props) {
  const [booked, setBooked] = useState(undefined);
  const [times, setTimes] = useState(undefined);

  const FindDate = useCallback(
    async (date) => {
      const param = {
        start: moment(date).startOf("day").format(),
        end: moment(date).endOf("day").format(),
      };

      setTimes({
        start: moment(date).startOf("day").format("HH:mm"),
        end: moment(date).endOf("day").format("HH:mm"),
      });
      //? Find the dates from the schedule
      let people = await props.ScheduleDataService.ExportReport(param);
      let NewArray = [];
      if (people?.length > 0) {
        for (const e of people) {
          if (e.Table_number !== null) {
            NewArray.push(e);
          }
        }
      }
      setBooked(NewArray);
    },
    [props.ScheduleDataService]
  );
  const [value, setValue] = React.useState(new Date());

  function calendarChooseDate(date) {
    FindDate(date);
    setValue(date);
  }
  useEffect(() => {
    const date = moment();
    FindDate(date);
  }, [FindDate]);

  return (
    <SimpleBar autoHide={false} style={{ height: "100%" }}>
      <Stack
        direction={{ xs: "row", xl: "row" }}
        spacing={2}
        style={{ width: "100%", padding: "0.5rem", justifyContent: "space-between" }}
      >
        <div style={{ width: "50%" }}>
          <MobileDatePicker
            label="Choose date"
            inputFormat="dd/MM/yyyy"
            value={value}
            onChange={(newValue) => {
              calendarChooseDate(newValue);
            }}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
        <Button
          sx={{ display: { xs: "none", sm: "flex" } }}
          type={"button"}
          onClick={props.handleOpen}
        >Open office floor plan</Button>
      </Stack>
      <div style={{ width: "95%" }}>
        <Modal
          open={props.open}
          onClose={props.handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <ChooseTableModal
            times={times}
            handleModal={props.handleClose}
            selectedDate={value}
          ></ChooseTableModal>
        </Modal>

        <TablesWithBookings
          ConverterClass={props.ConverterClass}
          scheduled={booked}
        ></TablesWithBookings>
      </div>
    </SimpleBar>
  );
}
export function TablesWithBookings(props) {
  const [Booked, setBooked] = useState();
  const [Tables, setTables] = useState();
  const CheckIfBooked = useCallback(() => {
    let booked = [];
    if (props.scheduled !== undefined) {
      for (const e of props.scheduled) {
        booked.push({
          Table: `tb${e.Table_number}`,
          BookedFrom: moment(
            e.Date_start_time ? e.Date_start_time : e.BookedFrom
          ).format("HH:mm"),
          BookedTo: moment(
            e.Date_end_time ? e.Date_end_time : e.BookedTo
          ).format("HH:mm"),
          User: e.User,
          email: e.Email,
          date: new Date(),
        });
      }
    }
    setBooked(booked);
  }, [props]);

  useEffect(() => {
    CheckIfBooked();
  }, [props, CheckIfBooked]);

  useEffect(() => {
    if (Booked !== undefined) {
      const BookedTable = Booked.map((e, index) => {
        return [
          e.Table !== "tbnull" ? e.Table.substring(2, 8) : "Not at Office",
          <PersonWithDetails
            multi={true}
            user={e.User}
            email={e.email}
          ></PersonWithDetails>,
          e.BookedFrom,
          e.BookedTo,
        ];
      });
      setTables(BookedTable);
    }
  }, [Booked]);

  const tableHead = ["Table", "Person", "Start", "End"];
  const tableHeaderColor = "info";
  return (
    <SimpleBar style={{ height: "100%" }} autoHide={false}>
      {Tables && (
        <Stack
          style={{ height: "100%", overflow: "hidden !important" }}
          tokens={{ childrenGap: 20 }}
        >
          <CustomTable
            tableHeaderColor={tableHeaderColor}
            tableHead={tableHead}
            tableData={Tables}
          ></CustomTable>
        </Stack>
      )}
    </SimpleBar>
  );
}
export function ScheduledHours(props) {
  const classes = useStyles();
  const [hours, setHours] = useState();

  const getHours = useCallback(async () => {

    let EmployeeID = ''
    try {
      EmployeeID = TokenService.getEmployeeData().id
    } catch (error) {
      alert("Error: EmployeeID not found in Emply, please contact supervisor")
      return
    }

    let param = {
      UserUUID: TokenService.getUserID(),
      EmployeeID: EmployeeID,
    };

    if (props.period === "period") {
      const thisPeriod = await salaryService.get_current_period();
      param.start = moment(thisPeriod.data[0].Date_start).startOf("day").format();
      param.end = moment(thisPeriod.data[0].Date_end).endOf("day").format();
    } else if (props.period === "month") {
      param.start = moment().startOf("month").startOf("day").format();
      param.end = moment().endOf("month").endOf("day").format();
    }
    let getHours = await ScheduleDataService.GetByDayAndUser(param);
    let amount = 0;
    getHours.data.forEach((e) => {
      amount = parseFloat(amount) + parseFloat(e.Hours);
    });
    setHours(parseFloat(amount));
  }, [props.period]);

  useEffect(() => {
    getHours();
  }, [getHours]);

  return <h3 className={classes.cardTitle}>{!hours ? 0 : hours.toFixed(2)}</h3>;
}
export function PersonaCard() {
  const [File, StoreFile] = useState();
  const [Feedback, setFeedback] = useState();
  async function changeImage() {
    const status = await Functions.changeUserImage(File);
    StoreFile(undefined);
    setFeedback(status);
  }
  return (
    <Stack tokens={{ childrenGap: 10 }}>
      <div className="personlarge">
        <Stack direction="row" spacing={4}>
          <Person
            // personDetails={personDetails}
            personQuery="me"
            view={PersonViewType.avatar}
            showPresence={false}
            fetchImage={true}
          ></Person>
        </Stack>
      </div>

      <Stack className="UploadImageContainer">
        <label htmlFor="avatar">Update image</label>
        <input
          type="file"
          id="avatar"
          name="avatar"
          accept="image/png, image/jpeg"
          onChange={(e) => {
            StoreFile(e.target.files[0]);
          }}
        />

        {File && <div>File: {File.name}</div>}
        {Feedback && <div style={{ marginBottom: "1rem" }}>{Feedback}</div>}
        {File && (
          <Button
            variant="contained"
            color="primary"
            type="button"
            onClick={changeImage}
          >
            Confirm upload
          </Button>
        )}
      </Stack>
    </Stack>
  );
}
export function LocationDropDownOptions({ PropsFunc }) {
  const [Store] = useContext(GlobalState);
  const [value, setValue] = useState('');

  const changeInput = useCallback(
    (e) => {
      setValue(e.target.value);
      PropsFunc(e);
    },
    [PropsFunc]
  );

  return (
    <Box>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-labels">Select Location</InputLabel>
        <Select
          labelId="demo-simple-select-labels"
          id="demo-simple-select"
          value={value}
          label="Location"
          name={"Location"}
          onChange={(val) => changeInput(val)}
        >
          {Store.Locations.map((e) => {
            return e.Location === "" ? (
              <MenuItem key={654894168413564} value={""}>
                Clear
              </MenuItem>
            ) : (
              <MenuItem key={e.Location} value={e.Location}>
                {e.Location}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
  // return <DropDown PropsFunc={PropsFunc} placeHolder={placeHolder} label={label} required={required} options={Options.Locations}></DropDown>;
}
export function HoursLogged() {
  const [Store] = useContext(GlobalState);
  const [hours, setHours] = useState(0);
  const classes = useStyles();

  const getDates = useCallback(async () => {
    let type = Store.SalaryOverviewOptions.type;
    let period = Store.SalaryOverviewOptions.period;

    let EmployeeID = ''
    try {
      EmployeeID = TokenService.getEmployeeData().id
    } catch (error) {
      alert("Error: EmployeeID not found in Emply, please contact supervisor")
      return
    }


    const param = {
      period_id: undefined,
      user_uuid: EmployeeID,
      filter: type,
    };

    if (period) {
      param.period_id = period.id;
    } else {
      const thisPeriod = await salaryService.get_current_period();
      param.period_id = thisPeriod.data[0].id;
    }
    if (param.period_id === undefined) {
      return <h3 className={classes.cardTitle}>0</h3>;
    }
    const getHours = await HoursDataService.getHoursInSalaryPeriod(param);
    if (getHours.data.length === 0) {
      setHours(0);
    } else {
      try {
        setHours(parseFloat(getHours.data[0]["Hours"]));
      } catch (err) {
        setHours(parseFloat(0));
      }
    }
  }, [Store, classes]);

  useEffect(() => {
    getDates();
    // eslint-disable-next-line
  }, [Store.SalaryOverviewOptions.period, Store.SalaryOverviewOptions.type]);

  return <h3 className={classes.cardTitle}>{hours.toFixed(2)}</h3>;
}
export function DefferedHours() {
  const [Store] = useContext(GlobalState);
  const classes = useStyles();
  const [hours, setHours] = useState(0);
  const getDates = useCallback(async () => {
    let type = Store.SalaryOverviewOptions.type;
    let period = Store.SalaryOverviewOptions.period;

    let EmployeeID = ''
    try {
      EmployeeID = TokenService.getEmployeeData().id
    } catch (error) {
      alert("Error: EmployeeID not found in Emply, please contact supervisor")
      return
    }
    const param = {
      period_id: undefined,
      user_uuid: EmployeeID,
      filter: type,
    };
    if (period) {
      param.period_id = period.id;
    } else {
      const thisPeriod = await salaryService.get_current_period();
      param.period_id = thisPeriod.data[0].id;
    }
    if (param.period_id === undefined) {
      return <h3 className={classes.cardTitle}>0</h3>;
    }
    let getHours = await bankService.getDebitHours(param);
    if (getHours.data.length === 0) {
      setHours(0);
    } else {
      try {
        setHours(parseFloat(getHours.data[0].Hours));
      } catch (err) {
        setHours(parseFloat(0));
      }
    }
  }, [Store, classes]);

  // if (props.successMessage) {
  //   getDates();
  // }

  useEffect(() => {
    getDates();
    // eslint-disable-next-line
  }, [Store.SalaryOverviewOptions]);

  return <h3 className={classes.cardTitle}>{hours.toFixed(2)}</h3>;
}
export function CreditHours() {
  const [Store] = useContext(GlobalState);
  const [hours, setHours] = useState(0);
  const classes = useStyles();
  const getDates = useCallback(async () => {
    let type = Store.SalaryOverviewOptions.type;
    let period = Store.SalaryOverviewOptions.period;
    let EmployeeID = ''
    try {
      EmployeeID = TokenService.getEmployeeData().id
    } catch (error) {
      alert("Error: EmployeeID not found in Emply, please contact supervisor")
      return
    }
    const param = {
      period_id: undefined,
      user_uuid: EmployeeID,
      debit: false,
      credit: true,
      filter: type,
    };
    if (period) {
      param.period_id = period.id;
    } else {
      const thisPeriod = await salaryService.get_current_period();
      param.period_id = thisPeriod.data[0].id;
    }
    if (param.period_id === undefined) {
      return <h3 className={classes.cardTitle}>0</h3>;
    }
    let getHours = await bankService.getCreditHours(param);
    try {
      setHours(parseFloat(getHours.data.Hours));
    } catch (err) {
      setHours(parseFloat(0));
    }
  }, [Store, classes]);

  useEffect(() => {
    getDates();
    // eslint-disable-next-line
  }, [Store.SalaryOverviewOptions]);

  return <h3 className={classes.cardTitle}>{hours.toFixed(2)}</h3>;
}
export function NetHours(props) {
  const [Store] = useContext(GlobalState);
  const [hours, setHours] = useState(0);
  const classes = useStyles();

  const getDates = useCallback(async () => {
    let type = Store.SalaryOverviewOptions.type;
    let period = Store.SalaryOverviewOptions.period;
    let EmployeeID = ''
    try {
      EmployeeID = TokenService.getEmployeeData().id
    } catch (error) {
      alert("Error: EmployeeID not found in Emply, please contact supervisor")
      return
    }
    const param = {
      period_id: period.id,
      user_uuid: EmployeeID,
      filter: type,
    };
    if (period.id !== undefined) {
      param.period_id = period.id;
    } else {
      const thisPeriod = await salaryService.get_current_period();
      param.period_id = thisPeriod.data[0].id;
    }
    let getHours = await bankService.getNetHoursInSalaryPeriod(param);
    try {
      if (getHours.data.Hours === null) {
        setHours(0);
      } else {
        setHours(parseFloat(getHours.data.Hours));
      }
    } catch (err) {
      setHours(parseFloat(0));
    }
  }, [Store]);

  useEffect(() => {
    getDates();
    // eslint-disable-next-line
  }, [Store.SalaryOverviewOptions]);
  return <h3 className={classes.cardTitle}>{hours.toFixed(2)}</h3>;
}

export function TasksHours() {
  const [Tasks, setTask] = useState(0);
  const thisMonth = moment().format("MMMM-YYYY");

  const getHours = useCallback(async (dates) => {
    const delays2 = 80;
    const durations2 = 500;
    let EmployeeID = ''
    try {
      EmployeeID = TokenService.getEmployeeData().id
    } catch (error) {
      alert("Error: EmployeeID not found in Emply, please contact supervisor")
      return
    }
    const param = {
      start: dates.start,
      end: dates.end,
      uuid: EmployeeID,
    };

    let getHours = await HoursDataService.ExportHoursForPerson(param);
    let grouped4 = [];

    getHours.data.forEach(function (a) {
      if (!this[a.Hour.Task]) {
        this[a.Hour.Task] = {
          Task: a.Hour.Task,
          Hours: 0,
        };
        grouped4.push(this[a.Hour.Task]);
      }
      for (const e of a.Ledgers) {
        this[a.Hour.Task].Hours =
          parseFloat(this[a.Hour.Task].Hours) + parseFloat(e.Hours);
      }
    }, Object.create(null));

    let labels = [];
    let series = [];
    for (const e of Object.values(grouped4)) {
      const arr = Object.values(e);
      labels.push(arr[0]);
      series.push(arr[1]);
    }

    const emailsSubscriptionChart = {
      data: {
        labels: labels,
        series: [series],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        low: 0,
        high: Math.max(...series),
        chartPadding: {
          top: 0,
          right: 5,
          bottom: 5,
          left: 0,
        },
      },
      scales: {
        y: {
          grid: {
            drawBorder: false,
            display: true,
            drawOnChartArea: true,
            drawTicks: false,
            borderDash: [5, 5],
          },
          ticks: {
            display: true,
            padding: 10,
            color: "#9ca2b7",
            font: {
              size: 11,
              // family: typography.fontFamily,
              style: "normal",
              lineHeight: 2,
            },
          },
        },
        x: {
          grid: {
            drawBorder: false,
            display: false,
            drawOnChartArea: true,
            drawTicks: true,
          },
          ticks: {
            display: true,
            color: "#9ca2b7",
            padding: 10,
            font: {
              size: 11,
              // family: typography.fontFamily,
              style: "normal",
              lineHeight: 2,
            },
          },
        },
      },
      responsiveOptions: [
        [
          "screen and (max-width: 640px)",
          {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc: function (value) {
                return value[0];
              },
            },
          },
        ],
      ],
      animation: {
        draw: function (data) {
          if (data.type === "bar") {
            data.element.animate({
              opacity: {
                begin: (data.index + 1) * delays2,
                dur: durations2,
                from: 0,
                to: 1,
                easing: "ease",
              },
            });
          }
        },
      },
    };
    setTask(emailsSubscriptionChart);
  }, []);

  const getDates = useCallback(async () => {
    const thisPeriod = await salaryService.find_period_by_month(thisMonth);
    getHours({
      start: thisPeriod.data[0]?.Date_start,
      end: thisPeriod.data[0]?.Date_end,
    });
  }, [getHours, thisMonth]);

  useEffect(() => {
    getDates();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ChartistGraph
        className="ct-chart citrineChart"
        data={Tasks.data}
        type="Bar"
        options={Tasks.options}
        responsiveOptions={Tasks.responsiveOptions}
        listener={Tasks.animation}
      />
      {/* icon, title, description, height, chart */}
      {/* <VerticalBarChart title={"Hello"} height={50} description={"random"} chart={tryy} ></VerticalBarChart> */}
    </>
  );
}
export function TreeNation() {
  const [Tasks, setTask] = useState(0);

  const getHours = useCallback(async () => {
    const delays = 80;
    const durations = 500;
    let EmployeeID = ''
    try {
      EmployeeID = TokenService.getEmployeeData().id
    } catch (error) {
      alert("Error: EmployeeID not found in Emply, please contact supervisor")
      return
    }
    const param = {
      start: moment().startOf("month").startOf("day").format(),
      end: moment().endOf("month").endOf("day").format(),
      uuid: EmployeeID,
    };

    let getHours = await HoursDataService.ExportHoursForPerson(param);

    let grouped4 = [];
    getHours.data.forEach(function (a) {
      if (!this[a.Hour.Date]) {
        this[a.Hour.Date] = {
          Date: moment(a.Hour.Date).format("DD"),
          Meetings: 0,
        };
        grouped4.push(this[a.Hour.Date]);
      }
      this[a.Hour.Date].Meetings =
        parseFloat(this[a.Hour.Date].Meetings) + parseFloat(a.Hour.Meetings);
    }, Object.create(null));
    grouped4 = orderBy(
      grouped4,
      function (o) {
        return new moment(o.Date);
      },
      ["asc"]
    );

    let labels = [];
    let series = [];
    for (const e of Object.values(grouped4)) {
      const arr = Object.values(e);
      labels.push(arr[0]);
      series.push(arr[1]);
    }
    const dailySalesChart = {
      data: {
        labels: labels,
        series: [[...series]],
      },
      options: {
        lineSmooth: Chartist.Interpolation.cardinal({
          tension: 0,
        }),
        low: 0,
        high: Math.max(...series) + 5,
        chartPadding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      // for animation
      animation: {
        draw: function (data) {
          if (data.type === "line" || data.type === "area") {
            data.element.animate({
              d: {
                begin: 600,
                dur: 700,
                from: data.path
                  .clone()
                  .scale(1, 0)
                  .translate(0, data.chartRect.height())
                  .stringify(),
                to: data.path.clone().stringify(),
                easing: Chartist.Svg.Easing.easeOutQuint,
              },
            });
          } else if (data.type === "point") {
            data.element.animate({
              opacity: {
                begin: (data.index + 1) * delays,
                dur: durations,
                from: 0,
                to: 1,
                easing: "ease",
              },
            });
          }
        },
      },
    };
    setTask(dailySalesChart);
  }, []);

  useEffect(() => {
    getHours();
    // eslint-disable-next-line
  }, []);

  return (
    <ChartistGraph
      className="ct-chart"
      data={Tasks.data}
      type="Line"
      options={Tasks.options}
      listener={Tasks.animation}
    />
  );
}
export function UpcominSchedule() {
  const [Tables, setTables] = useState();
  const [progress, setProgress] = React.useState(0);

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ width: "100%", mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  LinearProgressWithLabel.propTypes = {
    /**
     * The value of the progress indicator for the determinate and buffer variants.
     * Value between 0 and 100.
     */
    value: PropTypes.number.isRequired,
  };

  const init = useCallback(async () => {
    const param = {
      start: moment().startOf("day").format("YYYY-MM-DD"),
      limit: 5,
      offset: 0,
    };
    const data = await ScheduleDataService.GetScheduleWithLimitAndOffset(param);
    let EmployeeID = ''
    try {
      EmployeeID = TokenService.getEmployeeData().id
    } catch (error) {
      alert("Error: EmployeeID not found in Emply, please contact supervisor")
      return
    }
    const param2 = {
      start: moment().startOf("month").startOf("day").format(),
      end: moment().endOf("month").endOf("day").format(),
      uuid: EmployeeID,
    };

    let getActualHours = await HoursDataService.ExportHoursForPerson(param2);

    const param3 = {
      start: moment().add("month").startOf("month").startOf("day").format(),
      end: moment().add("month").endOf("month").endOf("day").format(),
      UserUUID: TokenService.getUserID(),
      EmployeeID: EmployeeID,
    };

    let getHours = await ScheduleDataService.GetByDayAndUser(param3);
    // getHours.data.length === 0 ? getHours = 0 : getHours = 0

    let actual = 0;

    if (getActualHours.data.length !== 0) {
      getActualHours.data.forEach((e) => {
        actual = parseFloat(actual) + parseFloat(e.Ledgers[0].Hours);
      });
    }
    let sched = 0;
    if (getHours.data.length !== 0) {
      getHours.data.forEach((e) => {
        sched = parseFloat(sched) + parseFloat(e.Hours);
      });
    }

    if (sched !== 0 && actual !== 0) {
      const percentage = converterClass.calDiffInPercentage(sched, actual);
      setProgress(percentage);
    } else {
      setProgress("Not applicable");
    }

    const BookedTable = data.map((e, index) => {
      return [
        moment
          .utc(e.Date_start_time)
          .tz(moment.tz.guess())
          .format("DD-MM-YYYY - HH:mm"),
        moment
          .utc(e.Date_end_time)
          .tz(moment.tz.guess())
          .format("DD-MM-YYYY - HH:mm"),
        e.Hours,
        e.Location,
      ];
    });
    setTables(BookedTable);
  }, []);

  useEffect(() => {
    init();
  }, []);
  const tableHead = ["Start", "End", "Hours", "Location"];
  const tableHeaderColor = "red";
  return (
    <SimpleBar autoHide={false} style={{ height: "100%" }}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <LinearProgressWithLabel value={progress} />
        </Box>
        {Tables && (
          <CustomTable
            tableHeaderColor={tableHeaderColor}
            tableHead={tableHead}
            tableData={Tables}
          ></CustomTable>
        )}
      </div>
    </SimpleBar>
  );
}
