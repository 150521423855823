import http from "../Common_service/rest_http_common";
import moment from "moment"
import bankService from "./bank.service";
import { TokenService } from "services";
import ConverterClass from "App/Functions/converterClass.js";

async function mergeLedgerAndHoursMetadata(data) {

  // for (const e of Ledgers) {
  //   const row = {
  //     Transaction_ID: e.Transaction_ID,
  //     Status: e.Status === "Deleted" ? "Deleted" : "Logged",
  //     Hours: e.Hours,
  //     Type: e.Type,
  //     id: num,
  //     StatusProof: e.Status,
  //   };
  //   for (const h of Hours) {
  //     if (h.Transaction_ID === e.Transaction_ID) {
  //       row.TeamID = h.Hour.Team;
  //       row.User = TokenService.getEmployeeData();
  //       row.Team = undefined;
  //       row.Contacts = h.Hour.Contacts;
  //       row.Meetings = h.Hour.Meetings;
  //       row.Date = h.Hour.Date;
  //       row.Description = h.Hour.Description;
  //       row.Project = h.Hour.Project;
  //       row.Task = h.Hour.Task;
  //       row.Location = h.Hour.Location;
  //     }
  //   }
  //   rows.push(row);
  //   num++;
  // }
  // return rows
  const mergedResults = data.map((result, index) => ({
    ...result.Hour,
    ...result.Ledgers[0],
    transactionId: result.Transaction_ID,
    Status: result.Ledgers[0].Status === "Deleted" ? "Deleted" : "Logged",
    TeamID: result.Hour.Team,
    id: index,
    StatusProof: result.Ledgers[0].Status,
    Team: undefined
  }));
  return await mergedResults;
}
class HoursDataService {
  getAll() {
    return http.get("/Hours");
  }

  get(id) {
    return http.get(`/Hours/${id}`);
  }

  create(data) {
    return http.post("/Hours", data);
  }

  update(id, data) {
    return http.put(`/Hours/${id}`, data);
  }

  delete(id) {
    return http.delete(`/Hours/${id}`);
  }
  Bulkdelete(payload) {
    return http.delete("/Hours/delete/bulk", {
      data:
        payload
    });
  }

  async GetTransactions(Transaction_ID) {
    const data = await http.get(`/Hours/transactions/id/${Transaction_ID}`);
    return data;
  }

  async ExportHoursForPerson(param) {
    const data = await http.get(`/Hours/user/${param.uuid}/start/${param.start}/end/${param.end}`);
    return data;
  }

  async ExportReport(param) {
    const data = await http.get(`/Hours/start/${param.start}/end/${param.end}`);
    return data;
  }

  async ExportReportWithDepartmentsArray(param) {
    const data = await http.post(`/Hours/Departments`, param);
    return data;
  }


  async sumByMonth(param) {
    const data = await http.get(
      `/Hours/user/${param.user}/start/${param.start}/end/${param.end}/sum/hours`
    );
    return data;
  }

  async getHoursInSalaryPeriod(param) {
    const data = await http.get(`/Hours/user/${param.user_uuid}/period/${param.period_id}?filter=${JSON.stringify(param.filter)}`);
    return data;
  }

  async Treenation(param) {
    const params = {
      start: moment().startOf("month").startOf("day").format(),
      end: moment().endOf("month").endOf("day").format(),
    }
    const data = await http.get(`/Hours/user/${param}/start/${params.start}/end/${params.end}/sum/meetings`);
    return data;
  }
  async AvgContacts(param) {
    const params = {
      start: moment().startOf("month").startOf("day").format(),
      end: moment().endOf("month").endOf("day").format(),
    }
    const data = await http.get(`/Hours/user/${param}/start/${params.start}/end/${params.end}/avg/contacts`);
    return data;

  }
  async AvgContactsForProject(param) {
    const params = {
      start: moment().startOf("month").startOf("day").format(),
      end: moment().endOf("month").endOf("day").format(),
    }
    const data = await http.get(`/Hours/project/${param}/start/${params.start}/end/${params.end}/avg/contacts`);
    return data;
  }
  async update_self_service(id, putdata) {
    const data = await http.put(`/Hours/self_service/${id}`, putdata);
    return data;
  }





  async RetrieveHours(Period, Selection) {
    if (Period?.id === undefined) {
      throw new Error("CurrentPeriod is undefined")
    }
    let accountID;
    try {
      accountID = TokenService.getEmployeeData().id
    } catch (error) {
      accountID = TokenService.getUser().id
    }


    let startOfCalendarYear;
    let endOfCalendarYear;
    if(Selection){
      startOfCalendarYear = moment(Selection).startOf("year").startOf("day").format();
      endOfCalendarYear = moment(Selection).endOf("year").endOf("day").format();
    }else{
      startOfCalendarYear = moment().startOf("year").startOf("day").format();
      endOfCalendarYear = moment().endOf("year").endOf("day").format();
    }
    

    

    let filter = "Normal"

    const param = {
      start: startOfCalendarYear,
      end: endOfCalendarYear,
      UserUUID: TokenService.getUserID(),
      uuid: accountID,
      filter: filter,
      user_uuid: accountID,
    };

    if (Selection) {
      //? prepare array of objects of dates (as many objects there are weeks)
      // If the first day and last day exist in between param.start and param.end
      if (moment(Selection.start).isBetween(param.start, param.end) && moment(Selection.end).isBetween(param.start, param.end)) {
      } else {
        // if neither exist
        if (!moment(Selection.start).isBetween(param.start, param.end) && !moment(Selection.end).isBetween(param.start, param.end)) {
          param.start = moment(Selection.start).startOf("day").format()
          param.end = moment(Selection.start).endOf("day").format()
          // If either the first day or last day exist in between param.start and param.end
        } else if (moment(Selection.start).isBetween(param.start, param.end) || moment(Selection.end).isBetween(param.start, param.end)) {
          // which one exists
          if (moment(Selection.start).isBetween(param.start, param.end)) {
            param.end = moment(Selection.end).endOf("day").format()
          } else {
            param.start = moment(Selection.start).startOf("day").format()
          }
        } else {
          // which one doesnt exist
          if (moment(Selection.start).isBetween(param.start, param.end)) {
            param.start = moment(Selection.start).startOf("day").format()
          }
          else {
            param.end = moment(Selection.start).endOf("day").format()
          }
        }
      }
    }
    let HoursOverview = []
    let HorusOverview = await this.ExportHoursForPerson(param);
    HorusOverview = HorusOverview.data;
    const AllHours = await mergeLedgerAndHoursMetadata(HorusOverview);

    let HoursInPeriod = []
    try {
      for (const h of AllHours) {
        if (moment(h.Date).isBetween(moment(Period.Date_start).local().startOf('day'), moment(Period.Date_end).local().endOf('day'), null, '[]')) {
          HoursInPeriod.push(h)
        }
      }
    } catch (error) {
      console.log(error)
    }

    let HoursInSelection = []
    if (Selection) {
      for (const h of AllHours) {
        if (moment(h.Date).isBetween(moment(Selection.start).local().startOf('day'), moment(Selection.end).local().endOf('day'), null, '[]')) {
          HoursInSelection.push(h)
        }
      }
    }


    HoursOverview = AllHours
    const meetingsSold = await this.Treenation(
      accountID
    );
    let Meetings = 0
    if (meetingsSold.data.length > 0) {
      for (const d of meetingsSold.data) {
        Meetings = Meetings + parseFloat(d.totalMeeting)
      }
    }
    const param_aux = {
      period_id: Period.id,
      user_uuid: accountID,
    };
    const sumHoursMonth = await this.getHoursInSalaryPeriod(
      param_aux
    );
    let MonthlyLoggedHours = []
    try {
      let months = [];
      HoursOverview.forEach(function (a) {
        const name = moment(a.Date).format("MMMM");
        if (!this[name]) {
          this[name] = {
            Month: name,
            Hours: 0,
          };
          months.push(this[name]);
        }
        this[name].Hours =
          parseFloat(this[name].Hours) + parseFloat(a.Hours);
      }, Object.create(null));
      MonthlyLoggedHours = months
    } catch (error) {
      console.log(error)
    }


    let Hours = 0
    if (sumHoursMonth.data.length > 0) {
      for (const d of sumHoursMonth.data) {
        Hours = Hours + parseFloat(d.Hours)
      }
    }
    const avgContacts = await this.AvgContacts(
      accountID
    );

    let Contacts = 0
    if (avgContacts.data.length > 0) {
      for (const d of avgContacts.data) {
        Contacts = Contacts + parseFloat(d.avg)
      }
      Contacts.toFixed(2)
    }

    const param_aux2 = {
      user_uuid: accountID,
      start: moment.utc().startOf("month").startOf("day").format(),
      end: moment.utc().endOf("month").endOf("day").format(),
    }
    let HoursByMonth = await this.sumByMonth(param_aux2)
    if (HoursByMonth.data.length === 0) {
      HoursByMonth = 0
    } else {
      HoursByMonth = await HoursByMonth.data[0].totalHours
    }
    if (isNaN(HoursByMonth)) {
      HoursByMonth = 0
    }
    HoursByMonth = parseFloat(HoursByMonth).toFixed(2)
    const Overview = { ...param_aux, credit: true, debit: false, filter: 'normal' }
    let Credit = await bankService.getCreditHours(Overview);
    Overview.credit = false
    Overview.debit = true
    let Debit = await bankService.getDebitHours(Overview);
    let Net = await bankService.getNetHoursInSalaryPeriod(Overview);
    Credit = parseFloat(await Credit.data.Hours)
    Debit = await Debit.data.length === 0 ? parseFloat(0) : parseFloat(Debit.data[0].Hours)
    Net = parseFloat(await Net.data.Hours)
    return {
      Hours,
      Meetings, Contacts, Credit, Net, Debit, HoursByMonth, HoursOverview, MonthlyLoggedHours, HoursInPeriod, HoursInSelection
    }
  }

}

export default new HoursDataService();

