import { useEffect, useState } from "react";
// react-router-dom components
import { useLocation, Link } from "react-router-dom";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
// @mui material components
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
// Material Dashboard 2 React components
import MDBox from "App/Views/Components/Material_ui/MDBox";
import Typography from "App/Views/Components/Material_ui/Typography";
// Material Dashboard 2 React example components
import SidenavCollapse from "App/Views/Components/Material_ui/Sidenav/SidenavCollapse";
import styles from "App/Views/Components/classes";
// Custom styles for the Sidenav
import SidenavRoot from "App/Views/Components/Material_ui/Sidenav/SidenavRoot";
import sidenavLogoLabel from "App/Views/Components/Material_ui/Sidenav/styles/sidenav";
import { makeStyles } from "@material-ui/styles";
// Material Dashboard 2 React context
import LogHoursInterface from "App/Views/Users/Hours/Log/logHoursInterface";
import YourSchedule from "App/Views/Users/Schedule/YourSchedule";
import Schedule from "App/Views/Users/Schedule/OfficeSchedule/Schedule";
import { uniqueId } from "lodash";
import {
  useMaterialUIController,
  setMiniSidenav,
  setTransparentSidenav,
  setWhiteSidenav,
} from "context";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import { Badge, Card, CardContent, CardHeader, Modal, Popover } from "@mui/material";
import OfficeSchedule from "App/Views/Users/Schedule/OfficeSchedule";
import { OfficeScheduleStoreProvider } from "context/store.js"

const useStyles = makeStyles(styles);

function Sidenav({ color, brand, emplyLogo, brandName, emplyActive, routes, handleDarkMode, ...rest }) {
  const icons = {
    Schedule: (
      <AccessTimeFilledIcon
        style={{ minWidth: "40px", color: "white" }}
      ></AccessTimeFilledIcon>
    ),
    Hours: (
      <HourglassFullIcon
        style={{ minWidth: "40px", color: "white" }}
      ></HourglassFullIcon>
    ),
    Admins: (
      <AdminPanelSettingsIcon
        style={{ minWidth: "40px", color: "white" }}
      ></AdminPanelSettingsIcon>
    ),
  };
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } =
    controller;
  const location = useLocation();
  const collapseName = location.pathname;
  let textColor = "white";
  const classes = useStyles();
  if (transparentSidenav || (whiteSidenav && !darkMode)) {
    textColor = "dark";
  } else if (whiteSidenav && darkMode) {
    textColor = "inherit";
  }
  const closeSidenav = () => setMiniSidenav(dispatch, true);
  useEffect(() => {
    // A function that sets the mini state of the sidenav.
    function handleMiniSidenav() {
      setMiniSidenav(dispatch, window.innerWidth < 1200);
      setTransparentSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : transparentSidenav
      );
      setWhiteSidenav(
        dispatch,
        window.innerWidth < 1200 ? false : whiteSidenav
      );
    }
    /** 
     The event listener that's calling the handleMiniSidenav function when resizing the window.
    */
    window.addEventListener("resize", handleMiniSidenav);
    // Call the handleMiniSidenav function to set the state with the initial value.
    handleMiniSidenav();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleMiniSidenav);
    // eslint-disable-next-line
  }, [dispatch, location]);


  const [open2, setOpen2] = useState(false);
  const handleOpen2 = () => setOpen2(true);
  const handleClose2 = () => setOpen2(false);


  const [open3, setOpen3] = useState(false);
  const handleOpen3 = () => setOpen3(true);
  const handleClose3 = () => setOpen3(false);

  const [open4, setOpen4] = useState(false);
  const handleOpen4 = () => setOpen4(true);
  const handleClose4 = () => setOpen4(false);


  // Render all the routes from the routes.js (All the visible items on the Sidenav)
  const renderRoutes = routes.map((e, index) => {
    if (e[0].type === "divider") {
      return (
        <Divider
          key={e.key + uniqueId() + index}
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
      );
    } else if (e[0].type === "title") {
      return (
        <Typography
          color={textColor}
          display='block'
          variant='caption'
          fontWeight='bold'
          textTransform='uppercase'
          pl={3}
          mt={2}
          mb={1}
          ml={1}
          key={e.key + uniqueId()}
        >
          {e[0].title}
        </Typography>
      );
    }
    else if (e.length === 1) {
      return e.map(
        ({ type, name, icon, title, noCollapse, key, href, route }) => {
          if (type === "collapse") {
            if (key === "logHours") {
              return (
                <>
                  <SidenavCollapse
                    key={key + uniqueId() + index}
                    name={name}
                    icon={icon}
                    active={route === collapseName}
                    noCollapse={noCollapse}
                    onClick={(e) => handleOpen2()}
                  />
                  <Modal
                    key={key + uniqueId() + index}
                    open={open2}
                    onClose={handleClose2}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'
                  >
                    <LogHoursInterface
                      key={key + uniqueId() + index}
                      handleClose={handleClose2}
                    ></LogHoursInterface>
                  </Modal>
                </>
              );
              // return ;
            } else if (key === "/Schedule") {
              return (
                <>
                  <SidenavCollapse
                    key={key + uniqueId() + index}
                    name={name}
                    icon={icon}
                    active={route === collapseName}
                    noCollapse={noCollapse}
                    onClick={(e) => handleOpen3()}
                  />
                  <Modal
                    key={key + uniqueId() + index}
                    open={open3}
                    onClose={handleClose3}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'
                    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                  >
                    <YourSchedule
                      key={key + uniqueId() + index}

                      handleClose={handleClose3}
                    ></YourSchedule>
                  </Modal>
                </>
              );
              // return ;
            } else if (key === "/Schedule/D1") {
              return (
                <>
                  <SidenavCollapse
                    key={key + uniqueId() + index}
                    name={name}
                    icon={icon}
                    active={route === collapseName}
                    noCollapse={noCollapse}
                    onClick={(e) => handleOpen4()}
                  />
                  <Modal
                    key={key + uniqueId() + index}
                    open={open4}
                    onClose={handleClose4}
                    aria-labelledby='modal-modal-title'
                    aria-describedby='modal-modal-description'
                    style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
                  >
                    <OfficeScheduleStoreProvider>
                      <OfficeSchedule
                        key={key + uniqueId() + index}
                        handleClose4={handleClose4}
                      ></OfficeSchedule>
                    </OfficeScheduleStoreProvider>
                  </Modal>
                </>
              );
              // return ;
            } else {
              return (
                <Link key={key + uniqueId() + index} to={route}>
                  <SidenavCollapse
                    key={key + uniqueId() + index}

                    name={name}
                    icon={icon}
                    active={route === collapseName}
                  />
                </Link>
              );
            }
          } else {
            return null;
          }
        }
      );
    }

  });
  const colorForActive = emplyActive === "success" ? "black" : "white";
  const textForActive = emplyActive === "success" ? "Active" : "Not Active";
  const textForActivePop = emplyActive === "success" ? "This means that contacting Emply for your Employee information was successful." : "This means that contacting Emply for your Employee information was unsuccessful. You will experience limited functionality until this is resolved. Please contact your supervisor and ask them to confirm you are active Employee in Emply.";

  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const open = Boolean(anchorEl);
  const [openM, setOpen] = useState(emplyActive !== "success");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <SidenavRoot
        {...rest}
        variant='permanent'
        ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
      >
        <MDBox pt={3} pb={1} px={4} textAlign='center'>
          <MDBox
            display={{ xs: "block", xl: "none" }}
            position='absolute'
            top={0}
            right={0}
            p={1.625}
            onClick={closeSidenav}
            sx={{ cursor: "pointer" }}
          >
            <Typography variant='h6' color='secondary'>
              <Icon sx={{ fontWeight: "bold" }}>close</Icon>
            </Typography>
          </MDBox>
          <MDBox
            component={Link}
            to='/'
            display='flex'
            style={{ flexDirection: "column" }}
            justifyContent='center'
          >
            {brand && (
              <MDBox component='img' src={brand} alt='Brand' height='auto' />
            )}
            <MDBox
              width={!brandName && "100%"}
              sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
            ></MDBox>
          </MDBox>
        </MDBox>
        <Divider
          light={
            (!darkMode && !whiteSidenav && !transparentSidenav) ||
            (darkMode && !transparentSidenav && whiteSidenav)
          }
        />
        <List>{renderRoutes}</List>
        {/*         <MDBox p={2} mt='auto' width="50%">
          {emplyLogo && (
            <>
              <Badge aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose} badgeContent={<Typography variant={"p"} component={"p"} sx={{ color: colorForActive + "!important" }}>{textForActive}</Typography>} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} color={emplyActive}>
                <MDBox component='img' src={emplyLogo} alt='emplyLogo' height='auto' width="100%" />
              </Badge>

              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >


                <Typography sx={{ p: 1, zIndex: 1000 }}>{textForActivePop}</Typography>
              </Popover>
            </>
          )}
        </MDBox> */}
      </SidenavRoot>
      <Modal
        open={openM}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      >
        <Card sx={{ width: "40%" }}>
          <CardHeader
            title={"Error fetching Employee Information"}
          ></CardHeader>
          <CardContent>
            <Typography variant="body2">
              This is an informational modal informing you that Emply (our HR system) is either currently unavailable or you may not be <strong>active</strong> in Emply.<br /><br />
            </Typography>

            <Typography sx={{ fontWeight: "bold" }} variant="body2">
              You will experience limited functionality until this is resolved.<br /><br />
            </Typography>
            <Typography sx={{ fontStyle: "italic", textDecoration: "underline" }} variant="body2">
              Please contact your supervisor and ask them to confirm if you are an active in Emply.
            </Typography>
          </CardContent>
        </Card>
      </Modal>
    </>
  );
}

// Setting default values for the props of Sidenav
Sidenav.defaultProps = {
  color: "info",
  brand: "",
};

// Typechecking props for the Sidenav
Sidenav.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  brand: PropTypes.string,
  brandName: PropTypes.string.isRequired,
  routes: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Sidenav;