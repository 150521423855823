import { useContext, useCallback } from "react";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import { GlobalState } from "context/store.js";

export function DepartmentTreeFallback({ PropsFunc, name, passedSelected }) {
  const [Store] = useContext(GlobalState);
  const deepFind = useCallback((arr, search) => {
    for (var obj of Object.values(arr)) {
      if (search(obj)) {
        return obj;
      }
      if (obj.SubDepartments) {
        var deepResult = deepFind(obj.SubDepartments, search);
        if (deepResult) {
          return deepResult;
        }
      }
    }
    return null;
  }, []);

  const handleSelect = (event, nodeIds) => {
    event.target.name = name;
    event.target.value = nodeIds[0];
    PropsFunc(event, name, event.target);
  };
  let defExpand = [Store.Departments.HQ.id];
  if (passedSelected) {
    let right = deepFind(Store.Departments, function (obj) {
      return obj.id === passedSelected;
    });
    if (right === null) {
      right = Object.values(Store.Departments).filter((x) => {
        return x.id === passedSelected;
      });
    }
    defExpand[1] = right.parentId;
  }

  return (
    <TreeView
      aria-label='file system navigator'
      defaultCollapseIcon={<ExpandMoreIcon fontSize='medium' />}
      defaultExpandIcon={<ChevronRightIcon fontSize='medium' />}
      defaultExpanded={defExpand}
      defaultSelected={passedSelected ? passedSelected : null}
      onNodeSelect={handleSelect}
      multiSelect
      sx={{
        height: "auto",
        flexGrow: 1,
        maxWidth: 400,
        width: "fit-content",
        overflowY: "auto",
      }}
    >
      <TreeItem
        fontSize='small'
        nodeId={Store.Departments.HQ.id}
        label='HQ'
      >
        {Store.Departments.HQ.SubDepartments.map((e, index) => {
          return (
            <TreeItem
              fontSize='small'
              id={e.id}
              key={index}
              nodeId={e.id}
              label={e.title}
            >
              {e.SubDepartments
                ? e.SubDepartments.map((e, index2) => {
                  return (
                    <TreeItem
                      fontSize='small'
                      id={e.id}
                      key={index2}
                      nodeId={e.id}
                      label={e.title}
                      name={name}
                      value={e.id}
                    />
                  );
                })
                : null}
            </TreeItem>
          );
        })}
      </TreeItem>
    </TreeView>
  );
}
