

// Material Dashboard 2 React Base Styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

// Material Dashboard 2 React Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";
import borders from "assets/theme/base/borders";
const { transparent, light, info, secondary, text, primary, warning, error, success } = colors;
const { size } = typography;

const outlined = {
  base: {
    minHeight: pxToRem(39),
    color: text.main,
    padding: `${pxToRem(9)} ${pxToRem(24)}`,

    "&:hover": {
      opacity: 0.75,
    },
    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(16)} !important`,
    },
  },
  small: {
    minHeight: pxToRem(31),
    padding: `${pxToRem(6)} ${pxToRem(18)}`,
    fontSize: size.xs,

    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(12)} !important`,
    },
  },
  large: {
    minHeight: pxToRem(46),
    padding: `${pxToRem(12)} ${pxToRem(64)}`,
    fontSize: size.sm,
    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(22)} !important`,
    },
  },
  primary: {
    borderColor: primary.main,
    "&:hover": {
      backgroundColor: primary.main,
    },
  },
  warning: {
    borderColor: warning.main,
    "&:hover": {
      backgroundColor: warning.main,
    },
  },
  error: {
    borderColor: error.main,
    "&:hover": {
      backgroundColor: error.main,
    },
  },
  secondary: {
    borderColor: secondary.main,
    "&:hover": {
      backgroundColor: secondary.main,
    },
  },
  info: {
    borderColor: info.main,
    "&:hover": {
      backgroundColor: info.main,
    },
  },
  success: {
    borderColor: success.main,
    "&:hover": {
      backgroundColor: success.main,
    },
  },

};

export default outlined;
