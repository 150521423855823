// External Libraries
import moment from "moment";
import { useDebouncedCallback } from "App/Functions/debounce";

// React related imports
import { useState, useEffect, useCallback, useContext } from "react";

// Material UI imports
import {
  Button,
  Grid,
  Typography,
  IconButton,
  CardActions,
  Stack,
} from "@mui/material";
import { makeStyles } from "@material-ui/styles";
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker'
import {
  CheckBox,
} from "App/Views/Components/Custom/MUIComponents";
import CloseIcon from "@mui/icons-material/Close";
import Cards from "App/Views/Components/Material_ui/Card/Card.js";
import CardHeader from "App/Views/Components/Material_ui/Card/CardHeader.js";
import styles from "App/Views/Components/classes";
import MDBox from "App/Views/Components/Material_ui/MDBox";
import CardFooter from "App/Views/Components/Material_ui/Card/CardFooter";

// Contexts and Services
import { GlobalState } from "context/store.js";
import ScheduleDataService from "services/REST_service/schedule.service";
import TokenService from "services/REST_service/token.service";
import TeamsDataService from "services/REST_service/teams.service";

// Custom components and functions
import ConverterClass from "App/Functions/converterClass.js";
import { WarningNotification } from "App/Views/Components/Custom/MUIComponents";
import { ControlledRadioButtonsGroup } from "App/Views/Components/Custom/CustomComponents";
import { Departments } from "App/Views/Emply";
import { DepartmentTreeFallback } from "App/Views/Components/Custom/DepartmentFallback";
import Timers from "./components/timers";
import useWindowSize from "App/Functions/useWindowSize";
import ChooseTableModal from "App/Views/Components/Modals/chooseTable";
import "simplebar/dist/simplebar.min.css";

const useStyles = makeStyles(styles);

export default function ScheduleEdit(props) {
  const [location, setLocation] = useState(props.WorkData.Location);
  const size = useWindowSize();
  // eslint-disable-next-line
  const [Store, setStore] = useContext(GlobalState);
  const [team, setTeam] = useState(
    TokenService?.getUser()?.EmployeeData?.departmentId
  );
  const classes = useStyles();
  const [selectedDate, setSelectedDate] = useState(
    props.WorkData.Original.Start
  );
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [times, setTimes] = useState({
    FormTimeEnd: props.WorkData.Original.End,
    FormTimeStart: props.WorkData.Original.Start,
  });

  const [state, setState] = useState({
    IsWorking: undefined,
    message: undefined,
    scheduled: undefined,
    officeSelected: false,
  });

  const [tables, setTables] = useState(false);
  const debounce = useDebouncedCallback((callback, value) => {
    callback(value);
  }, 300);

  const changeTable = useCallback(async () => {
    handleOpen();
  }, []);

  const initiate = useCallback(
    async (data) => {
      if (data.length > 0) {
        let array = [];
        for (const e of data) {
          if (e.Table_number !== 0) {
            if (e.Table_number !== null) {
              if (parseInt(e.Table_number, 10)) {
                if (e.Table_number === parseInt(Store.table)) {
                  array.push(e);
                }
              }
            }
          }
        }
        if (array.length > 0) {
          let truthy = false;
          for (const e of array) {
            if (e.User_UUID !== TokenService.getUserID()) {
              truthy = true;
            } else if (e.User_UUID === TokenService.getUserID()) {
              setStore({
                Notification: {
                  color: "success",
                  icon: "notifications",
                  title: "Notice!",
                  content: `Table ${Store.table} is already booked by you, you will keep it for this edit`,
                  dateTime: moment(),
                },
              });
            }
          }
          if (truthy) {
            setStore({
              Notification: {
                color: "error",
                icon: "warning",
                title: "Failed",
                content: `Table ${Store.table} is already booked at this time, please select another table`,
                dateTime: moment(),
              },
            });
            setTables(true);
            return true;
          }
        }
      } else {
        setTables(false);
      }
    },
    [setTables, setStore, Store.table]
  );

  function PropsFunc(data) {
    setTeam((team) => ({
      ...team,
      Team: data.target.value,
    }));
  }
  const getBookedTables = useCallback(
    async (date) => {
      const selectedDate = date;
      const newParam = {
        start: moment(selectedDate).startOf("day").format(),
        end: moment(selectedDate).endOf("day").format(),
        uuid: TokenService.getUserID(),
      };
      const Booked = await ScheduleDataService.GetBooked(newParam);
      newParam.email = TokenService.getUser().email;
      const TestWorking = await ScheduleDataService.pingTrueFalse(newParam);
      let isWorkingState = TestWorking.data;
      if (
        moment(props.WorkData.Original.Start).isSame(
          moment(selectedDate),
          "day"
        )
      ) {
        isWorkingState = false;
      }
      if (location === "Office") {
        if (debounce(initiate, Booked.data)) {
          return;
        } else {
          setTables(false);
          return;
        }
      }
      setState((state) => ({
        ...state,
        scheduled: Booked.data,
        IsWorking: isWorkingState,
      }));
      return;
    },
    [initiate, setState, debounce, props, location]
  );

  useEffect(() => {
    if (location === "Office") {
      debounce(getBookedTables, selectedDate);
    }
    // eslint-disable-next-line
  }, [location, Store.table, selectedDate]);

  useEffect(() => {
    setState((state) => ({
      ...state,
      selectedDate: new Date(props.WorkData.Original.Start),
    }));
  }, [props]);

  const deepFind = useCallback((arr, search) => {
    for (var obj of Object.values(arr)) {
      if (search(obj)) {
        return obj;
      }
      if (obj.SubDepartments) {
        var deepResult = deepFind(obj.SubDepartments, search);
        if (deepResult) {
          return deepResult;
        }
      }
    }
    return null;
  }, []);

  let TeamOriginal = deepFind(Store.Departments, function (obj) {
    return obj.id === props.WorkData.Team;
  });
  const changeInput = useCallback(
    async (e) => {
      e.preventDefault();
      e.persist(e);
      const elements = await ConverterClass.formToJSON(e.target.elements);
      const id = props.WorkData.ID;
      delete elements.id;
      if (team === undefined) {
        setStore({
          Notification: {
            color: "error",
            icon: "warning",
            title: "Failed",
            content: "Please select the team first",
            dateTime: moment(),
          },
        });
        return;
      }

      elements.Team = team;
      elements.Date_start_time = ConverterClass.makeMomentDateWithTime(
        selectedDate,
        elements.start
      );
      elements.Date_end_time = ConverterClass.makeMomentDateWithTime(
        selectedDate,
        elements.end
      );

      delete elements.start;
      delete elements.end;
      Object.entries(elements).forEach((entry) => {
        // eslint-disable-next-line
        const [key, value] = entry;
        if (key.includes("ChoiceGroup")) {
          delete elements[key];
        }
      });
      elements.Location = location;
      if (elements.Location === "Office") {
        if (Store.table === undefined) {
          setStore({
            Notification: {
              color: "error",
              icon: "warning",
              title: "Failed",
              content: "Please select the table first",
              dateTime: moment(),
            },
          });
          return;
        }
      }
      elements.Table_number = parseInt(Store.table);
      delete elements.Table;

      const duration = moment.duration(
        moment(elements.Date_end_time).diff(moment(elements.Date_start_time))
      );
      elements.Hours = duration.asHours().toFixed(2);
      if (elements.Break === "true") {
        elements.Hours = parseFloat(elements.Hours - 0.5).toFixed(2);
      }
      elements.Break === "true" ? (elements.Break = 1) : (elements.Break = 0);
      if (elements.Location !== "Office") {
        elements.Table_number = null;
      }
      if (elements.Team?.Team !== undefined) {
        elements.Team = elements.Team.Team;
      }
      const teams = deepFind(Store.Departments, function (obj) {
        return obj.id === elements.Team;
      });
      if (elements.Team === undefined){
        setStore({
          Notification: {
            color: "error",
            icon: "warning",
            title: "Failed",
            content: 'Could not find team, make sure it is selected',
            dateTime: moment(),
          },
        });
        return
      }
      if(teams === undefined){
        setStore({
          Notification: {
            color: "error",
            icon: "warning",
            title: "Failed",
            content: 'Could not find team, make sure it is selected',
            dateTime: moment(),
          },
        });
        return
      }
      ScheduleDataService.update(id, elements)
        .then(async (response) => {
          if (response.request.statusText === "OK") {
            props.handleClose();
          }
          const Schedules = await ScheduleDataService.RetrieveSchedules(Store.CurrentPeriod, props.WorkData.Original.Start);
          setStore({ Schedules })
        })
        .catch((e) => {
          console.log(e);
        });
    },
    [Store, setStore, location, props, selectedDate, team, deepFind]
  );

  function deleteEntry(id) {
    ScheduleDataService.delete(id)
      .then(async (response) => {
        if (response.request.statusText === "OK") {
          setStore({
            Notification: {
              color: "success",
              icon: "check",
              title: "Success",
              content: "Successfully deleted the schedule",
              dateTime: moment(),
            },
          });
          props.handleClose();
          const Schedules = await ScheduleDataService.RetrieveSchedules(Store.CurrentPeriod, props.WorkData.Original.Start);
          setStore({ Schedules })
        }
      })
      .catch((e) => {
        setStore({
          Notification: {
            color: "error",
            icon: "warning",
            title: "Failed",
            content: 'something went wrong',
            dateTime: moment(),
          },
        });
      });
  }
  useEffect(() => {
    if (props.WorkData.ID) {
      setTimes((state) => ({
        ...state,
        FormTimeEnd: props.WorkData.End,
        FormTimeStart: props.WorkData.Start,
      }));
    }
  }, [props.WorkData.End, props.WorkData.Start, props.WorkData.ID]);

  const officeClicked = useCallback((truthy, value) => {
    if (truthy) {
      handleOpen();
    }
    if (value !== undefined) {
      setLocation(value);
    }
  }, []);
  const Start = props.WorkData.Original.start;
  //? Edit the schedule modal

 
  return (
    <div style={{
      pointerEvents: "none",
      overflow: "hidden",

    }}>
      <div
        style={{
          height: "100%",
          display: "flex",
          pointerEvents: "none",
          overflow: "hidden",
        }}
      >
        <form
          style={{
            margin: "auto",
            pointerEvents: "all",
            overflow: "hidden",
            height: "100%",
            width: size.width < 500 ? "100%" : "80%",
          }}
          key={props.WorkData.ID}
          onSubmit={changeInput}
          id={props.WorkData.ID}
        >
          <Cards
            className="Settings"
            style={{
              pointerEvents: "all",
              overflow: "hidden",
              height: size.width < 500 ? "95vh" : "90vh",
              width: "100%",
              overflowY: "scroll",
              paddingBottom: size.width < 500 ? "5rem" : 0,
            }}
          >
            <CardHeader>
              <CardActions sx={{ justifyContent: "flex-end" }}>
                <IconButton
                  onClick={() => props.handleClose()}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <CloseIcon size="medium" sx={{ color: "white" }} />
                </IconButton>
              </CardActions>
              <Stack
                spacing={1}
                direction="row"
                alignContent="center"
                justifyContent="center"
                alignItems="center"
                flexWrap="wrap"
                divider={<p>|</p>}
              >
                <Typography component="h4" variant='h4' className={classes.cardTitle}>
                  {moment(props.WorkData.Original.Start).format("DD-MMMM-YYYY")}
                </Typography>
                <Typography component="h4" variant='h4' className={classes.cardTitle}>
                  {moment(props.WorkData.Original.Start).format("HH:mm")}-
                  {moment(props.WorkData.Original.End).format("HH:mm")}
                </Typography>
                <Typography component="h4" variant='h4' className={classes.cardTitle}>
                  {props.WorkData.Hours} Hours
                </Typography>
                <Typography component="h4" variant='h4' className={classes.cardTitle}>
                  {TeamOriginal.title}
                </Typography>
                <Stack
                  direction="row"
                  alignContent="center"
                  justifyContent="center"
                  alignItems="center"
                  spacing={1}
                >
                  <Typography component="h4" variant='h4' className={classes.cardTitle}>
                    {props.WorkData.Location}
                  </Typography>
                  {props.WorkData.Table_number && (
                    <Typography component="h4" variant='h4' className={classes.cardTitle}>
                      Table {props.WorkData.Table_number}
                    </Typography>
                  )}
                </Stack>
              </Stack>
            </CardHeader>
            <MDBox mt={2} mb={3} sx={{ padding: "2rem" }}>
              <Grid container spacing={3}>
                <Grid item justifyContent={"center"} xs={12} md={4} xl={4}>
                  {Store.Emply && (
                    <Departments
                      TeamsDataService={TeamsDataService}
                      PropsFunc={PropsFunc}
                      label={"Select your team first"}
                      placeHolder={"Team"}
                      required={true}
                    />
                  )}
                  {!Store.Emply && (
                    <DepartmentTreeFallback PropsFunc={PropsFunc} name={"Team"} />
                  )}

                  <input type="hidden" value={selectedDate} name="Date" />
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={4}
                  xl={4}
                  justifyContent={"center"}
                  sx={{ display: "flex" }}
                >
                  <StaticDatePicker
                    sx={{ ml: 5 }}
                    orientation="portrait"
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    value={selectedDate}
                    minDate={new Date()}
                    onChange={(newValue) => {
                      setSelectedDate(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </Grid>

                <Grid item spacing={2} xs={12} md={4} xl={4}>
                  <Stack direction="column" spacing={3} position="static">
                    <ControlledRadioButtonsGroup
                      currentState={location}
                      officeClicked={officeClicked}
                    />
                    {Store.table && location === "Office" ? (
                      <p className="ManualLabel">
                        Current table selection {Store.table}
                      </p>
                    ) : (
                      ""
                    )}
                    <Button onClick={(e) => handleOpen()} color="warning" variant="contained">
                      Choose Table
                    </Button>
                    <Stack direction="row" spacing={3} position="static">
                      <p className="ManualLabel">I will take a 30 minute break</p>
                      <CheckBox
                        label="I will take 30 minutes break"
                        name="Break"
                      ></CheckBox>
                    </Stack>

                    {state.officeSelected === true ? (
                      <WarningNotification
                        warningMessage={
                          "Please note!! This feature is not yet aware of tables already booked, nor can it book a table for you at this time. If you had a table booked on the day you are currently editing, then you will keep the table for the updated entry, so please make sure you are not overwriting another persons table booking."
                        }
                      />
                    ) : null}
                    <input type="hidden" value={Start} name="day" />
                    {state.message}
                  </Stack>

                  <Timers times={times} setTimes={setTimes}></Timers>
                </Grid>
              </Grid>
            </MDBox>
            <CardFooter>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <Grid item xs={12} md={6} xl={6}>

                  <Button
                    variant="contained"
                    disabled={tables ? true : false}
                    type="button"
                    color="error"
                    onClick={(e) => {
                      deleteEntry(props.WorkData.ID);
                    }}
                    style={{ width: "100%" }}
                  >
                    Delete this entry
                  </Button>
                </Grid>
                <Grid item xs={12} md={6} xl={6}>
                  {state.IsWorking ? (
                    <h3 className="InfoWarning">
                      You are already working on this day
                    </h3>
                  ) : (
                    <>
                      <Stack spacing={3}>
                        {tables && (
                          <Button
                            variant="contained"
                            disabled={tables ? true : false}
                            type="button"
                            color="warning"
                            onClick={changeTable}
                            style={{ width: "100%" }}
                          >
                            Click to change table
                          </Button>
                        )}
                      </Stack>

                      {!tables && (
                        <Button
                          variant="contained"
                          disabled={tables ? true : false}
                          type="submit"
                          color="success"
                          style={{ width: "100%" }}
                        >
                          Confirm Edit
                        </Button>
                      )}
                    </>
                  )}
                </Grid>
              </Grid>
            </CardFooter>
          </Cards>
        </form>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ChooseTableModal
          times={times}
          handleModal={handleClose}
          selectedDate={selectedDate}
        ></ChooseTableModal>
      </Modal>
    </div>
  );
}
