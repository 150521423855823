import { useState, useContext, useEffect, useCallback } from "react";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { GlobalState } from "context/store.js";
import ScheduleDataService from "services/REST_service/schedule.service";
import moment from "moment";
import NewTables from "App/Views/Components/Custom/newTables";
import ConverterClass from "App/Functions/converterClass.js";
import { TablesWithBookings } from "App/Views/Components/Custom/CustomComponents";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Card from "App/Views/Components/Material_ui/Card/Card.js";
import CardHeader from "App/Views/Components/Material_ui/Card/CardHeader.js";
import CardBody from "App/Views/Components/Material_ui/Card/CardBody.js";
import CardActions from "@mui/material/CardActions";
import styles from "App/Views/Components/classes";
import { makeStyles } from "@material-ui/styles";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Paper, Typography } from "@mui/material";

const useStyles = makeStyles(styles);

function ChooseTableModal({ selectedDate, times, handleModal, showAll }) {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.between("xs", "md"));

  // eslint-disable-next-line
  const [Store, setStore] = useContext(GlobalState);
  const [booked, setBooked] = useState(undefined);
  const classes = useStyles();

  const FindDate = useCallback(async () => {
    const param = {
      start: ConverterClass.makeMomentDateWithTime(selectedDate, times.start),
      end: ConverterClass.makeMomentDateWithTime(selectedDate, times.end),
    };
    //? Find the dates from the schedule
    let people = await ScheduleDataService.ExportReport(param);
    let NewArray = [];
    if (people?.length > 0) {
      for (const e of people) {
        const ob = {
          BookedFrom: e.Date_start_time,
          BookedTo: e.Date_end_time,
          Table: `tb${e.Table_number}`,
          User: e.User,
          date: moment(e.Date_end_time).format(),
          email: e.Email,
          Table_number: e.Table_number,
          Email: e.Email,
        };
        if (showAll) {
          NewArray.push(ob);
        }
        if (e.Table_number !== null && !showAll) {
          NewArray.push(ob);
        }

      }
    }
    setBooked(NewArray);
  }, [selectedDate, times, showAll]);

  useEffect(() => {
    FindDate();
  }, [times.start, FindDate]);

  if (booked === undefined) {
    return <div>Loading...</div>;
  }

  return (
    <Stack
      sx={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        pointerEvents: "none",
      }}
    >
      <Stack
        sx={{
          maxHeight: "100vh",
          width: { xs: "95vw", md: "90%" },
          overflow: "scroll",
          margin: "auto",
          pointerEvents: "all",
          background: 'transparent',
          display: "flex"
        }}
        direction={{ xs: "column", md: "row" }}
      >
        <Stack sx={{ width: { xs: "100%", md: "25vw" } }}>
          <Card
            style={{
              pointerEvents: "all",

            }}
          >
            <CardBody className="overFlowHidden" style={{ height: "80vh" }}>
              <Stack direction='row' alignContent='space-around' alignItems='center'>
                <Typography variant="h2" component="h2" sx={{ fontWeight: 700, fontSize: "20px" }} gutterBottom>Amount of People | {booked.length}</Typography>
                <IconButton sx={{ marginLeft: 'auto' }} onClick={() => handleModal()} color="primary" aria-label="upload picture" component="span">
                  <CloseIcon size="medium" color="error" />
                </IconButton>
              </Stack>

              <SimpleBar autoHide={false} style={{ height: "90%" }}>
                <Stack direction={{ xs: "column", md: "row" }} >
                  <Stack sx={{ overflow: "scroll", width: { xs: "100%", md: "25vw" } }}>
                    <TablesWithBookings ConverterClass={ConverterClass} scheduled={booked} buttonText={"CloseModal"}></TablesWithBookings>
                  </Stack>
                </Stack>
              </SimpleBar>
            </CardBody>
          </Card>
        </Stack>
        <Stack sx={{ width: { xs: "100%", md: "70vw" }, marginLeft: { md: "2rem" } }}>
          <Card
            style={{
              pointerEvents: "all",
            }}
          >
            <CardBody className="overFlowHidden" style={{ height: "80vh" }}>
              <Stack direction='row' alignContent='space-around' alignItems='center'>
                <Typography variant="h2" component="h2" sx={{ fontWeight: 700, fontSize: "20px" }} gutterBottom>Tables bookings for {moment(selectedDate).format("DD-MM-YYYY")}</Typography>
                <IconButton sx={{ marginLeft: 'auto' }} onClick={() => handleModal()} color="primary" aria-label="upload picture" component="span">
                  <CloseIcon size="medium" color="error" />
                </IconButton>
              </Stack>
              <SimpleBar autoHide={false} style={{ height: "auto" }}>
                <Stack direction={{ xs: "column", md: "row" }} >
                  <Stack sx={{ overflow: "scroll", width: { xs: "100vw", md: "70vw" } }}>
                    <NewTables booked={booked}></NewTables>
                  </Stack>
                </Stack>
              </SimpleBar>
            </CardBody>
          </Card>
        </Stack>
      </Stack>

    </Stack>
  );
}

export default ChooseTableModal;
