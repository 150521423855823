//? This component is the command bar on top of the office schedule. It will control the schedule
//? It has Components from the main components folder in the source folder that are passed down from the app.js
//?
import { useState, useContext, useCallback, memo } from "react";
import { PeoplePickerComp } from "App/Views/Components/Microsoft_MGT/MGTComponents";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { GlobalState, OfficeScheduleState } from "context/store.js";
import { Departments } from "App/Views/Emply";
import { TokenService } from "services";
import { DepartmentTreeFallback } from "App/Views/Components/Custom/DepartmentFallback";
import { Button, Paper, MenuItem, Stack, Typography, Modal, IconButton, Checkbox, FormControlLabel } from "@mui/material";
import styles from "App/Views/Components/classes";
import { makeStyles } from "@material-ui/styles";
import useWindowSize from "App/Functions/useWindowSize";
import { TeamsDataService } from "services";
import { LocationDropDownOptions } from "App/Views/Components/Custom/CustomComponents";
import { LocationsDataService } from "services";
import CalendarWeek from "./components/CalendarWeek";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import DateRangeIcon from "@mui/icons-material/DateRange";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import {
  useMaterialUIController,
} from "context";
import MonthlyScheduleOffice from "./Modal";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles(styles);
const CommandBar = memo(({ FilteredPersons, CalendarPicked, updateWeekArray, updateTimeLine, handleClose4 }) => {
  const [controller] = useMaterialUIController();
  const classes = useStyles();

  const [GlobalStore] = useContext(GlobalState);
  const [Store, setStore] = useContext(OfficeScheduleState);

  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const [SelectedTeam, setSelectedTeam] = useState(
    TokenService?.getUser()?.EmployeeData?.departmentId
  );
  const [openMonthly, setOpenMonthly] = useState(false);

  const {
    darkMode,
  } = controller;

  const [TeamName, setTeamName] = useState("");

  const PropsFunc = useCallback(
    (selectedOption) => {
      if (selectedOption?.target?.name === "Location") {
        if (selectedOption.target.value === "") {
          selectedOption.target.value = undefined;
        }
        setStore({
          filterBy: {
            ...Store.filterBy,
            Location: selectedOption.target.value,
          },
        })

      } else {
        if (selectedOption.target.name === "Team") {
          function deepFind(arr, search) {
            for (var obj of Object.values(arr)) {
              if (search(obj)) {
                return obj;
              }
              if (obj.SubDepartments) {
                var deepResult = deepFind(obj.SubDepartments, search);
                if (deepResult) {
                  return deepResult;
                }
              }
            }
            return null;
          }
          let right = deepFind(GlobalStore.Departments, function (obj) {
            return obj.id === selectedOption.target.value;
          });
          if (right === null) {
            right = Object.values(GlobalStore.Departments).filter((x) => {
              return x.id === selectedOption.target.value;
            });
          }
          setSelectedTeam(right);
          setTeamName(right?.title);

          setStore({
            filterBy: {
              ...Store.filterBy,
              Team: selectedOption.target.value,
            },
          })
        }
      }
    },
    [Store, setStore, GlobalStore]
  );

  const handleChange = (event) => {
    if (event.value === "") {
      setTeamName("");
      setStore({
        filterBy: {
          ...Store.filterBy,
          Team: undefined,
        },
      })
    }
    return;
  };

  const handleCheckboxLunch = () => {
    console.log("changed");
  }

  const handleOpenCalendar = () => {
    setOpenMonthly(true);
  }
  const handleCloseMonthly = () => {
    setOpenMonthly(false);
  }
  const size = useWindowSize();

  return (
    <>
      <Paper
        sx={{
          width: "100%",
        }}>
        <Stack
          direction={"row"}
          flexWrap="wrap"
          sx={{ paddingLeft: "1rem", marginTop: "1rem", width: "100%" }}
        >
          {Store.SelectedDays.Friday && (
            <Typography variant="p" component='p' className={classes.cardTitle} style={{ padding: 5, }}>
              {Store.SelectedDays.Friday.format("MMMM")} hours
            </Typography>
          )}
          {Store.OfficeFilters?.Hours !== null &&
            Store.OfficeFilters?.Hours !== undefined ? (
            <Typography variant="p" component='p' className={classes.cardTitle} style={{ padding: 5, }}>
              {Store.OfficeFilters?.Hours}
            </Typography>
          ) : (
            <Typography variant="p" component='p' className={classes.cardTitle} style={{ padding: 5, }}>
              0
            </Typography>
          )}
          <IconButton
            sx={{
              padding: 0,
              margin: 0,
              marginBottom: "auto",
              marginLeft: "auto",
              marginRight: "1rem",
            }}
            onClick={() => handleClose4()}
            color="info"
            aria-label="upload picture"
            component="span"
          >
            <CloseIcon size="large" color="error" />
          </IconButton>
        </Stack>
        <Stack
          direction={size.width < 500 ? "column" : "row"}
          textAlign="center"
          alignItems="center"
          justifyContent={"space-between"}
          spacing={2}
          flexWrap="wrap"
          sx={{ padding: "1rem", width: "100%" }}
        >
          <FormControl fullWidth style={{ padding: 0, width: size.width < 500 ? "90%" : "30%" }}>
            <InputLabel id="demo-simple-select-label">
              Filter by Team
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Filter by Team"
              open={open}
              onOpen={handleOpen}
              onClose={handleClose}
              value={TeamName}
              onChange={(e) => {
                handleChange(e.target);
              }}
            >
              <MenuItem value={""}>Clear filter</MenuItem>

              {GlobalStore.Emply && (
                <Departments
                  PropsFunc={PropsFunc}
                  passedSelected={
                    SelectedTeam?.id ? SelectedTeam?.id : undefined
                  }
                  TeamsDataService={TeamsDataService}
                  placeHolder={"Team"}
                  name={"Team"}
                  label={"Filter by Team"}
                />
              )}
              {!GlobalStore.Emply && (
                <DepartmentTreeFallback
                  TeamsDataService={TeamsDataService}
                  passedSelected={
                    SelectedTeam?.id ? SelectedTeam?.id : undefined
                  }
                  label={"Filter by Team"}
                  PropsFunc={PropsFunc}
                  placeHolder={"Team"}
                  name={"Team"}
                />
              )}
            </Select>
          </FormControl>
          <div style={{
            padding: 0, width: size.width < 500 ? "90%" : "30%", marginTop: size.width < 500 ? "1rem" : 0
          }}>
            <LocationDropDownOptions
              LocationsDataService={LocationsDataService}
              PropsFunc={PropsFunc}
              label={"Filter by locations"}
              name={"Location"}
              required={false}
            />
          </div>
          <div style={{ padding: 0, width: size.width < 500 ? "90%" : "30%", marginTop: size.width < 500 ? "1rem" : 0 }}>
            <PeoplePickerComp
              whichPassDown={"user"}
              id="PeoplePicker"
              passedDown={FilteredPersons}
            ></PeoplePickerComp>
          </div>
        </Stack>
        <Stack
          flexWrap="wrap"
          direction={{ md: "column", xxl: "row" }}
          textAlign="center"
          alignItems="center"
          justifyContent={{ md: "space-between" }}
          spacing={2}
          sx={{ padding: "1rem", width: "100%" }}
        >
          <Stack direction="row" spacing={1} sx={{ justifyContent: { md: "center", xxl: "space-between" }, width: { xxl: "60%" }, maxWidth: { xxl: "900px" } }} >
            <CalendarWeek
              darkMode={darkMode}
              CalendarPicked={CalendarPicked}
            ></CalendarWeek>

            <Stack alignItems='center' direction='row' style={{ cursor: "pointer", margin: "1rem" }} onClick={(e) => {
              updateTimeLine("Today");
            }}>
              <CalendarTodayIcon style={{ marginRight: "1rem" }} />
              <Typography
                variant="h6"
                component="h6">
                This week
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' style={{ cursor: "pointer", margin: "1rem" }} onClick={(e) => {
              updateWeekArray(true);
            }}>
              <DateRangeIcon style={{ marginRight: "1rem" }} />
              <Typography
                variant="h6"
                component="h6">
                Full week
              </Typography>
            </Stack>
            <Stack alignItems='center' direction='row' style={{ cursor: "pointer", margin: "1rem" }} onClick={(e) => {
              updateWeekArray(false);
            }}>
              <DateRangeIcon style={{ marginRight: "1rem" }} />
              <Typography
                variant="h6"
                component="h6">
                Work week
              </Typography>
            </Stack>
          </Stack>

          <Stack direction='row' spacing={1} justifyContent='space-around' >
            <Button variant='contained' endIcon={<DateRangeIcon />} onClick={(e) => {
              handleOpenCalendar();
            }}>
              Month
            </Button>
            <Button
              aria-label='Prev'
              onClick={(e) => {
                updateTimeLine("Prev");
              }}
              variant='contained'
            >
              <NavigateBeforeIcon />
              Previous Week
            </Button>

            <Button
              aria-label='Next'
              onClick={(e) => {
                updateTimeLine("Next");
              }}
              variant='contained'
            >
              Next Week
              <NavigateNextIcon />
            </Button>
          </Stack>

        </Stack>
      </Paper>

      <Modal sx={{ padding: { lg: "2rem" }, overflow: "scroll" }} open={openMonthly} onClose={handleCloseMonthly}>
        <MonthlyScheduleOffice handleClose={handleCloseMonthly}>
        </MonthlyScheduleOffice>
      </Modal>
    </>
  );
})
export default CommandBar
