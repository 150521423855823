import { useEffect, useState, useCallback } from "react";
// import People from "./people";
import "simplebar/dist/simplebar.min.css";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
// import _ from "lodash";
import { v4 as uuidv4 } from 'uuid';
import People from "./people";
import moment from "moment";
import { List } from "@mui/material";
export default function GridItem(props) {
  const [page, setpage] = useState(0);
  const [Persons, setPersons] = useState([]);
  // eslint-disable-next-line
  const [Filtered, setFiltered] = useState(true);

  function sortTime(a, b) {
    return moment(a.Date_start_time) - moment(b.Date_start_time);
  }
  const Create = useCallback(
    (array) => {
      if (array.length <= 0 || array === undefined) {
        return;
      }
      const arraySorted = array.sort(sortTime);
      let item = arraySorted.map((e, index) => {
        return (<People
          info={e}
          key={uuidv4()}
        ></People>)
      })
      return item
    },
    [],
  )
  const incrementCount = () => {
    setpage(page + 1);
  };
  ;

  useEffect(() => {
    const items = Create(props.People);
    setPersons(items);
  }, [props, page, Create]);

  return (
    <Stack spacing={1} alignItems='center' alignContent='center'
      style={{ maxHeight: "100%", height: "100%", overflow: "scroll", zIndex: 5, marginTop: "1rem", paddingBottom: "5rem" }}>
      {Filtered === false ? (
        <Button
          onClick={incrementCount}
          style={{ margin: "auto", marginTop: "1rem" }}
          variant='outlined'
        >
          Load more...
        </Button>
      ) : null}
      <List
        sx={{
          width: '100%',
          maxWidth: 360,
        }}
      >
        {Persons}
      </List>
      {Filtered === false ? (
        <Button
          onClick={incrementCount}
          style={{ margin: "auto", marginTop: "1rem" }}
          variant='outlined'
        >
          Load more...
        </Button>
      ) : null}
    </Stack>
  );
}
