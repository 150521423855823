import React, { useEffect, useState, useCallback } from "react";
import { Login } from "@microsoft/mgt-react";
import { Providers, ProviderState } from "@microsoft/mgt";
import { Msal2Provider } from "@microsoft/mgt-msal2-provider";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { AuthService } from "services";
import { ModalProvider } from "App/Views/Components/Modals/modalContext";
import { StoreProvider } from "context/store.js"
import { Box, CircularProgress, Typography } from "@mui/material";

let render = 0;

const LoadingAppIndicator = ({ status }) => {
  return (
    <Box sx={{ display: 'flex', width: "98vw", height: "98vh" }}>
      <Box sx={{ margin: "auto", display: "flex", flexDirection: "column", alignContent: "center", alignItems: 'center' }}>
        <CircularProgress sx={{ margin: "2rem" }} />
        <Typography >{status}</Typography>
      </Box>
    </Box>
  );
}
function useIsSignedIn() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  useEffect(() => {
    const updateState = () => {
      const provider = Providers.globalProvider;
      setIsSignedIn(provider && provider.state === ProviderState.SignedIn);
    };
    Providers.onProviderUpdated(updateState);
    updateState();
    return () => {
      Providers.removeProviderUpdatedListener(updateState);
    };
  }, []);
  return [isSignedIn];
}
export default function Authenticate() {
  const [AppComp, setApp] = useState(<LoadingAppIndicator status="Loading..." />);
  // eslint-disable-next-line
  useEffect(() => {
    /// ? old teams login if ends
    const Scopes = [
      "Calendars.Read",
      "Directory.AccessAsUser.All",
      "Directory.Read.All",
      "email",
      "Mail.Read",
      "Group.Read.All",
      "Group.ReadWrite.All",
      "GroupMember.Read.All",
      "GroupMember.ReadWrite.All",
      "Mail.ReadBasic",
      "offline_access",
      "openid",
      "People.Read.All",
      "Presence.Read",
      "Presence.Read.All",
      "profile",
      "Sites.Read.All",
      "Tasks.Read",
      "Tasks.ReadWrite",
      "User.Read",
      "User.Read.All",
      "User.ReadBasic.All",
      "User.ReadWrite",
      "User.ReadWrite.All",
    ];
    const config = {
      redirectUri: `${window.location.origin}`,
      authority:
        "https://login.microsoftonline.com/dd9fe8e0-ee42-4b62-b9a4-b796431bfdfe",
      cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: true,
      },
      scopes: Scopes,
      cacheLocation: "localStorage",
      storeAuthStateInCookie: true,
      clientId: "380f1112-8e95-4f6e-bd18-9a3e44ca9272",
    };
    Providers.globalProvider = new Msal2Provider(config);
  }, []);

  const [isSignedIn] = useIsSignedIn();

  const signJWT = useCallback(async () => {
    await AuthService.login(
      await Providers.globalProvider.getAccessToken({
        scopes: ["User.Read"],
      })
    );

    setApp(
      <BrowserRouter>
        <ModalProvider>
          <App></App>
        </ModalProvider>
      </BrowserRouter>
    );
  }, []);

  useEffect(() => {
    if (isSignedIn && render === 0) {
      signJWT();
      render++;
    }
    // eslint-disable-next-line
  }, [isSignedIn]);

  if (isSignedIn) {
    const user = AuthService.getCurrentUser();
    if (user) {
      return (
        <React.Suspense fallback={<div> Loading</div>}>
          <StoreProvider>
            {AppComp}
          </StoreProvider>
        </React.Suspense>
      );
    } else {
      return (
        <div className="NotAuthorized">
          <div>Please wait</div>
        </div>
      );
    }
  }
  if (!isSignedIn) {
    return (
      <div className="NotAuthorized">
        <div className="logo">
          <div className="logoForHeader"></div>
        </div>
        <h3>Welcome to Dialogue Time</h3>
        <Login></Login>
      </div>
    );
  }
}
