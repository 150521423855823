import React, { useEffect, useState, useCallback } from "react";
import { Providers, ProviderState } from "@microsoft/mgt";
import {
  Agenda,
  Todo,
  Person,
  PeoplePicker,
  PersonViewType,
  PersonCardInteraction,
  Get,
} from "@microsoft/mgt-react";
import { makeStyles } from "@material-ui/styles";
import styles from "App/Views/Components/classes";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardActions from "@mui/material/CardActions";
import { Link, Stack } from "@mui/material";
import SimpleBar from "simplebar-react";
import moment from "moment";
import Divider from "@mui/material/Divider";
import CardBody from "../../Components/Material_ui/Card/CardBody.js";
import { GraphInfoService } from "services";
import { styled } from "@mui/material/styles";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { red } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import converterClass from "App/Functions/converterClass.js";
import Paper from "@mui/material/Paper";
import business from "moment-business";
import { padding } from "@mui/system";
const useStyles = makeStyles(styles);

function useIsSignedIn() {
  const [isSignedIn, setIsSignedIn] = useState(false);
  useEffect(() => {
    const updateState = () => {
      const provider = Providers.globalProvider;
      setIsSignedIn(provider && provider.state === ProviderState.SignedIn);
    };
    Providers.onProviderUpdated(updateState);
    updateState();
    return () => {
      Providers.removeProviderUpdatedListener(updateState);
    };
  }, []);
  return [isSignedIn];
}
// TeamsProvider.microsoftTeamsLib = microsoftTeams;


// export function TodoList() {
//   // eslint-disable-next-line
//   const [isSignedIn] = useIsSignedIn();
//   return <Tasks className='Dashboard_Task' />;
// }

export function AgendaComp() {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // eslint-disable-next-line
  const [isSignedIn] = useIsSignedIn();
  return (
    <Agenda className='Dashboard_Task' preferredTimezone={timezone} days={1} />
  );
}

export function PersonalTodo() {
  return <Todo style={{ backgroundColor: "transparent" }}></Todo>;
}

export function PeoplePickerComp({ whichPassDown, mode, passedDown }) {
  // eslint-disable-next-line
  const [isSignedIn] = useIsSignedIn();
  let choice = "";

  if (mode !== undefined) {
    choice = "single";
  } else {
    choice = "multiple";
  }
  let userType = "user";
  if (whichPassDown !== undefined) {
    userType = "user";
  }
  return (
    <div className='PeoplePickerWrapper'>
      <div className='PeoplePickerInputWrapper'>
        <PeoplePicker
          userType={userType}
          selectionChanged={passedDown}
          selectionMode={choice}
        ></PeoplePicker>
      </div>
    </div>
  );
}




export function PersonWithDetails(props) {
  return (
    <Person
      personQuery={props.email}
      view={PersonViewType.twolines}
      showPresence={true}
      line1Property='givenName'
      personCardInteraction={PersonCardInteraction.hover}
    ></Person>
  );
}

// const PersonTemplate = (props) => {
//   const person = props.dataContext;
//   return <div>
//     {person.userPrincipalName}
//     {/* <Person userId={person.userPrincipalName}  fetchImage={true} showPresence={true}
//        line2Property="mail"></Person> */}
//   </div>;
// };
