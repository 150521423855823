
import colors from "assets/theme/base/colors";
const { dark } = colors;
const tableContainer = {
  styleOverrides: {
    root: {
      color: dark.main

    },
  },
};

export default tableContainer;
