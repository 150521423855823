/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React base styles
import colors from "assets/theme-dark/base/colors";

// Material Dashboard 2 React helper functions
import rgba from "assets/theme-dark/functions/rgba";
import pxToRem from "assets/theme-dark/functions/pxToRem";

const { dark, transparent, white } = colors;

const divider = {
  styleOverrides: {
    root: {
      backgroundColor: white.main,
      height: pxToRem(1),
      margin: `${pxToRem(6)} auto`,
      borderBottom: "none",
      opacity: 0.60,
      width: "100%"
    },
    // root: {
    //   backgroundColor: transparent.main,
    //   backgroundImage: `linear-gradient(to right, ${rgba(dark.main, 0)}, ${white.main}, ${rgba(
    //     dark.main,
    //     0
    //   )}) !important`,
    //   height: pxToRem(1),
    //   margin: `${pxToRem(16)} 0`,
    //   borderBottom: "none",
    //   opacity: 0.25,
    // },

    // vertical: {
    //   backgroundColor: transparent.main,
    //   backgroundImage: `linear-gradient(to bottom, ${rgba(dark.main, 0)}, ${white.main}, ${rgba(
    //     dark.main,
    //     0
    //   )}) !important`,
    //   width: pxToRem(1),
    //   height: "100%",
    //   margin: `0 ${pxToRem(16)}`,
    //   borderRight: "none",
    // },
    vertical: {
      backgroundColor: white.main,
      width: pxToRem(1),
      height: "90%",
      margin: `auto ${pxToRem(16)}`,
      borderRight: "none",
      opacity: 0.10,
    },

    // light: {
    //   backgroundColor: transparent.main,
    //   backgroundImage: `linear-gradient(to right, ${rgba(white.main, 0)}, ${rgba(
    //     dark.main,
    //     0.4
    //   )}, ${rgba(white.main, 0)}) !important`,

    //   "&.MuiDivider-vertical": {
    //     backgroundImage: `linear-gradient(to bottom, ${rgba(white.main, 0)}, ${rgba(
    //       dark.main,
    //       0.4
    //     )}, ${rgba(white.main, 0)}) !important`,
    //   },
    // },
    light: {
      backgroundColor: white.main,
      opacity: 0.20,

      "&.MuiDivider-vertical": {
        backgroundColor: white.main,
        opacity: 0.20,
      },
    },
  },
};

export default divider;
