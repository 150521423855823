import { useEffect, useContext, useCallback } from "react";
import CalendarGrid from "./components/CalendarGrid";
import moment from "moment";
import LinearProgress from "@mui/material/LinearProgress";
import Card from "App/Views/Components/Material_ui/Card/Card.js";
import { OfficeScheduleState } from "context/store.js";
import { ScheduleDataService } from "services";

export default function Schedule(props) {
  const [Store, setStore] = useContext(OfficeScheduleState);

  useEffect(() => {
    if (Store.SelectedDays.WeekDays.length !== 0) {
      props.initial(Store.SelectedDays.WeekDays)
    }
    // eslint-disable-next-line
  }, [Store.SelectedDays.WeekDays]);

  const Hours = useCallback(
    async (date) => {
      const month = moment(date)
        .startOf("month")
        .startOf("day")
        .format("YYYY-MM-DD");
      let monthly = await ScheduleDataService.GetMonthlyHours(
        month,
        Store.filterBy
      );
      if (monthly.data) {
        setStore({
          OfficeFilters: {
            ...Store.OfficeFilters,
            Hours: await monthly.data[0].Hours,
          }
        })
      }
    },
    [
      Store, setStore
    ]
  );
  useEffect(() => {
    Hours(Store.SelectedDays.Monday);
    // eslint-disable-next-line
  }, [Store.SelectedDays, Store.filterBy]);

  if (Store.SelectedDays === undefined) {
    return null
  } else {
    return (
      <Card style={{ background: "transparent", backgroundColor: "transparent", height: "83vh", marginTop: 0 }}>
        {Store.People === undefined ? (
          <LinearProgress></LinearProgress>
        ) : (
          <CalendarGrid
          ></CalendarGrid>
        )}
      </Card>
    );
  }
}
