// React imports
import { useState, useEffect, useCallback, useContext } from "react";

// Context imports
import { GlobalState } from "context/store.js";
import { ModalContext } from "App/Views/Components/Modals/modalContext";

// Services imports
import ScheduleDataService from "services/REST_service/schedule.service";
import TokenService from "services/REST_service/token.service";
import TeamsDataService from "services/REST_service/teams.service";

// Functions imports
import ConverterClass from "App/Functions/converterClass.js";
import { useDebouncedCallback } from "App/Functions/debounce";

// Custom components imports
import { CheckBox, DefaultBtn } from "App/Views/Components/Custom/MUIComponents";
import { ControlledRadioButtonsGroup } from "App/Views/Components/Custom/CustomComponents";
import { Departments } from "App/Views/Emply";
import ChooseTableModal from "App/Views/Components/Modals/chooseTable";
import { DepartmentTreeFallback } from "App/Views/Components/Custom/DepartmentFallback";
import { Notification } from "App/Views/Components/Custom/MUIComponents";

// MUI imports
import TextField from "@mui/material/TextField";
import Modal from "@mui/material/Modal";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import GridContainer from "App/Views/Components/Material_ui/Grid/GridContainer.js";
import GridItem from "App/Views/Components/Material_ui/Grid/GridItem.js";
import { Button, Typography } from "@mui/material";
import Card from "App/Views/Components/Material_ui/Card/Card.js";
import CardHeader from "App/Views/Components/Material_ui/Card/CardHeader.js";
import CardBody from "App/Views/Components/Material_ui/Card/CardBody.js";
import CardActions from "@mui/material/CardActions";

// Styles imports
import { makeStyles } from "@material-ui/styles";
import styles from "App/Views/Components/classes";

// Moment import
import moment from "moment";

// Debounce import
import useWindowSize from "App/Functions/useWindowSize";

const useStyles = makeStyles(styles);

export default function ScheduleAdd(props) {
  const classes = useStyles();
  const date = moment(props.WorkData.date).format();
  const [Store, setStore] = useContext(GlobalState);
  const [open, setOpen] = useState(false);
  const size = useWindowSize();

  const [disabledBtn] = useState(false);

  const debounce = useDebouncedCallback((callback, value) => {
    callback(value);
  }, 300);

  const deepFind = useCallback((arr, search) => {
    for (var obj of Object.values(arr)) {
      if (search(obj)) {
        return obj;
      }
      if (obj.SubDepartments) {
        var deepResult = deepFind(obj.SubDepartments, search);
        if (deepResult) {
          return deepResult;
        }
      }
    }
    return null;
  }, []);

  let { handleModal } = useContext(ModalContext);
  const [dateState, setDateState] = useState({
    chosenDays: [],
    selectedDate: date,
    start: "09:00",
    end: "16:30",
  });
  const [tables, setTables] = useState({
    TableIsBooked: false,
    tableMessage: [],
  });
  const [team, setTeam] = useState(
    TokenService?.getUser()?.EmployeeData?.departmentId
  );
  const [location, setLocation] = useState("Office");
  const confirmSelection = useCallback(
    async (e) => {
      e.preventDefault();
      e.persist(e);
      const elements = ConverterClass.formToJSON(e.target.elements);
      if (elements.Date === "") {
        setStore({
          Notification: {
            color: "error",
            icon: "warning",
            title: "Failed",
            content: "Please select the date",
            dateTime: moment(),
          },
        });
        return;
      } else if (elements.Time_Start === "") {
        setStore({
          Notification: {
            color: "error",
            icon: "warning",
            title: "Failed",
            content: "Please select the start time",
            dateTime: moment(),
          },
        });
        return;
      } else if (elements.Time_End === "") {
        setStore({
          Notification: {
            color: "error",
            icon: "warning",
            title: "Failed",
            content: "Please select the end time",
            dateTime: moment(),
          },
        });
        return;
      }
      let changedTeam = team
      if (team.Team !== undefined) {
        changedTeam = team.Team 
      }
      if (team === undefined) {
        setStore({
          Notification: {
            color: "error",
            icon: "warning",
            title: "Failed",
            content: "Please select the team first",
            dateTime: moment(),
          },
        });
        return;
      }
      const teams = deepFind(Store.Departments, function (obj) {
        return obj.id === changedTeam;
      });

      if(teams === undefined){
        setStore({
          Notification: {
            color: "error",
            icon: "warning",
            title: "Failed",
            content: "Please select the team first",
            dateTime: moment(),
          },
        });
        return;
      }

      elements.Team = teams.id;
      elements.Location = location;
      if (elements.Location === undefined) {
        setStore({
          Notification: {
            color: "error",
            icon: "warning",
            title: "Failed",
            content: "Please select the location first",
            dateTime: moment(),
          },
        });
        return;
      }
      if (elements.Location === "Office") {
        if (Store.table === undefined) {
          setStore({
            Notification: {
              color: "error",
              icon: "warning",
              title: "Failed",
              content: "Please select the table first",
              dateTime: moment(),
            },
          });
          return;
        }
      }
      elements.Date_start_time = ConverterClass.makeMomentDateWithTime(
        elements.Date,
        elements.Time_Start
      );
      elements.Date_end_time = ConverterClass.makeMomentDateWithTime(
        elements.Date,
        elements.Time_End
      );
      const duration = moment.duration(
        moment(elements.Date_end_time).diff(moment(elements.Date_start_time))
      );

      elements.User = TokenService.getUser().username;
      elements.Email = TokenService.getUser().email;
      elements.Hours = parseFloat(duration.asHours()).toFixed(2);
      elements.Table_number = parseInt(Store.table);
      if (elements.Location !== "Office") {
        elements.Table_number = null;
      }
      elements.User_UUID = TokenService.getUserID();
      if (elements.Break === "true") {
        elements.Hours = parseFloat(elements.Hours - 0.5).toFixed(2);
      }
      elements.Break === "true" ? (elements.Break = 1) : (elements.Break = 0);
      let EmployeeID = ''
      try {
        EmployeeID = TokenService.getEmployeeData().id
      } catch (error) {
        alert("Error: EmployeeID not found in Emply, please contact supervisor")
        return
      }
      elements.EmployeeID = EmployeeID;
      ScheduleDataService.create(elements)
        .then(async (response) => {
          if (response.request.statusText === "OK") {
            setStore({
              Notification: {
                color: "success",
                icon: "check",
                title: "Success",
                content: response.data.message,
                dateTime: moment(),
              },
            });
            props.handleClose();
          }
          try {
            let Schedules = await ScheduleDataService.RetrieveSchedules(Store.CurrentPeriod, moment(props.WorkData.date))
            setStore({ Schedules })
          } catch (error) {
            setStore({
              Notification: {
                color: "error",
                icon: "warning",
                title: "Failed",
                content: "Schedule was updated but failed to retrieve new schedules",
                dateTime: moment(),
              },
            });
          }
        })
        .catch((e) => {
          setStore({
            Notification: {
              color: "error",
              icon: "warning",
              title: "Failed",
              content: e.response.data.message,
              dateTime: moment(),
            },
          });
        });
    },
    [location, team, Store, setStore, props, deepFind]
  );

  const initiate = useCallback(async () => {
    if (location !== "Office") {
      setTables((state) => ({
        ...state,
        TableIsBooked: false,
        tableMessage: [],
      }));
      return;
    }
    const param = {
      start: moment(dateState.selectedDate).startOf("day").format(),
      end: moment(dateState.selectedDate).endOf("day").format(),
    };
    try {
      const data = await ScheduleDataService.ExportReport(param);
      //here
      if (data.length !== 0) {
        let array = [];
        for (const e of data) {
          if (e.Table_number !== 0) {
            if (e.Table_number !== null) {
              if (parseInt(e.Table_number, 10)) {
                if (e.Table_number === parseInt(Store.table)) {
                  array.push(e);
                }
              }
            }
          }
        }
        if (array.length > 0) {
          function changeTable() {
            const booked = {
              start: dateState.start,
              end: dateState.end,
              date: date,
            };
            handleModal({ booked, modal: "ChooseTableModal" });
          }
          const mapped = array.map((e, index) => (
            <>
              <Notification
                className="TableWarningMessage"
                key={index + "tableSelectionsMessage"}
                message={`Table ${Store.table} is already booked at this time, please select another table`}
              />
              <div onClick={changeTable}>
                <DefaultBtn
                  disabled={false}
                  BtnType={"button"}
                  text={"Click to change table"}
                />
              </div>
            </>
          ));
          setTables((state) => ({
            ...state,
            TableIsBooked: true,
            tableMessage: mapped,
          }));
        } else {
          setTables((state) => ({
            ...state,
            TableIsBooked: false,
            tableMessage: [],
          }));
        }
      }
    } catch (error) {
      setStore({
        Notification: {
          color: "error",
          icon: "warning",
          title: "Failed",
          content: "Failed to load schedule.",
          dateTime: moment(),
        },
      });
    }

    // Notification: {
    //   color: "success",
    //     icon: "check",
    //       title: "Success",
    //         content: "Failed to load schedule.",
    //           dateTime: moment(),
    //     },

  }, [date, dateState, Store, setTables, handleModal, location, setStore]);

  useEffect(() => {
    debounce(initiate);
    // eslint-disable-next-line
  }, [dateState, location, Store.table, debounce]);

  useEffect(() => {
    if (props && date) {
      setDateState((dateState) => ({
        ...dateState,
        selectedDate: date,
      }));
    }
  }, [props, date]);

  function PropsFunc(data) {
    setTeam((team) => ({
      ...team,
      Team: data.target.value,
    }));
  }
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const officeClicked = useCallback(
    (truthy, value) => {
      if (truthy) {
        if (Store.table === undefined) {
          handleOpen();
        }
      }
      if (value !== undefined) {
        setLocation(value);
      }
    },
    [Store.table]
  );

  function ResetForm() {
    // modalContent.getSchedule();
    // handleScheduleModal();
  }

  return (
    <form
      style={{
        margin: "auto",
        pointerEvents: "all",
        overflow: "hidden",
        width: size.width < 500 ? "100%" : "80%",
        height: "100%"
      }}
      onReset={ResetForm}
      onSubmit={confirmSelection}
    >

      <Card
        style={{
          pointerEvents: "all",
          overflowY: "scroll",
          height: size.width < 500 ? "100vh" : "90vh"
        }}
      >
        <CardHeader>
          <Typography component="h4" variant='h4' className={classes.cardTitle}>
            Now adding to | {moment(date).format("DD-MM-YYYY")}
          </Typography>
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <IconButton
              onClick={() => props.handleClose()}
              color="primary"
              aria-label="upload picture"
              component="span"
            >
              <CloseIcon size="medium" color="error" />
            </IconButton>
          </CardActions>
        </CardHeader>

        <CardBody style={{ padding: "2rem" }}>
          <GridContainer style={{ height: "fit-content", justifyContent: "center", paddingBottom: "20px" }}>
            <GridItem xs={12} sm={12} md={4}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                {Store.Emply && (
                  <Departments
                    TeamsDataService={TeamsDataService}
                    PropsFunc={PropsFunc}
                    label={"Select your team first"}
                    placeHolder={"Team"}
                    required={true}
                  />
                )}
                {!Store.Emply && (
                  <DepartmentTreeFallback PropsFunc={PropsFunc} name={"Team"} />
                )}
              </Stack>
            </GridItem>

            <GridItem
              xs={12}
              sm={12}
              md={4}
              sx={{ display: "flex" }}
            >
              <Stack
                direction="column"
                justifyContent="left"
                alignItems="left"
                spacing={3}
                sx={{ ml: { xs: 0, sm: 5 } }}
              >
                <ControlledRadioButtonsGroup
                  currentState={location}
                  officeClicked={officeClicked}
                />
                {Store.table && location === "Office" ? (
                  <p className="ManualLabel">
                    Current table selection {Store.table}
                  </p>
                ) : (
                  ""
                )}
                <Button onClick={(e) => handleOpen()} variant="contained">
                  Choose Table
                </Button>

                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: { xs: "center", sm: "left" },
                    alignItems: "center",
                  }}
                >
                  <Typography component="h4" variant='h4' className="ManualLabel">
                    I'll take a 30 minute break
                  </Typography>
                  <CheckBox></CheckBox>
                </Stack>
              </Stack>
            </GridItem>

            <GridItem xs={12} sm={12} md={4} spacing={2}>
              <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                spacing={3}
              >
                {tables.tableMessage}
                <Stack style={{ marginTop: "1rem" }} spacing={3}>
                  <TextField
                    name="Time_Start"
                    id="Time_Start"
                    label="Time start"
                    type="time"
                    defaultValue="09:00"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    sx={{ width: "100%" }}
                    onChange={(e) => {
                      setDateState((dateState) => ({
                        ...dateState,
                        start: e.target.value,
                      }));
                    }}
                  />{" "}
                  <TextField
                    name="Time_End"
                    id="Time_End"
                    label="Time end"
                    type="time"
                    defaultValue="16:30"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 300, // 5 min
                    }}
                    onChange={(e) => {
                      setDateState((dateState) => ({
                        ...dateState,
                        end: e.target.value,
                      }));
                    }}
                    sx={{ width: "100%" }}
                  />
                  <DefaultBtn
                    BtnType={"submit"}
                    disabled={
                      disabledBtn
                        ? true
                        : false || tables.TableIsBooked
                          ? true
                          : false
                    }
                    text={"Confirm Selection"}
                  />
                </Stack>
                <input type="hidden" value={date} name="Date" />
              </Stack>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <ChooseTableModal
          times={{ start: dateState.start, end: dateState.end }}
          handleModal={handleClose}
          selectedDate={date}
        ></ChooseTableModal>
      </Modal>
    </form>
  );
}
