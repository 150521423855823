

// Material Dashboard 2 React Base Styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";
import borders from "assets/theme/base/borders";
// Material Dashboard 2 React Helper Functions
import pxToRem from "assets/theme/functions/pxToRem";
const { borderRadius } = borders;

const { white, text, info, secondary, gradients, error } = colors;
const { size } = typography;

const contained = {
  base: {
    minHeight: pxToRem(37),
    padding: `${pxToRem(10)} ${pxToRem(24)}`,
    "&:hover": {
      backgroundColor: white.main,
      color: text.main,
    },

    "&:active, &:active:focus, &:active:hover": {
      opacity: 0.85,
    },

    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(16)} !important`,
    },
  },

  small: {
    minHeight: pxToRem(29),
    padding: `${pxToRem(6)} ${pxToRem(18)}`,
    fontSize: size.xs,
    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(12)} !important`,
    },
  },

  large: {
    minHeight: pxToRem(44),
    padding: `${pxToRem(12)} ${pxToRem(64)}`,
    fontSize: size.sm,
    "& .material-icon, .material-icons-round, svg": {
      fontSize: `${pxToRem(22)} !important`,
    },
  },

  primary: {
    backgroundColor: info.main,
    color: text.focus,
    "&:hover": {
      backgroundColor: gradients.info.state,
    },

    "&:focus:not(:hover)": {
      backgroundColor: info.focus,
    },
  },

  secondary: {
    color: white.main,
    backgroundColor: secondary.main,
    "&:hover": {
      backgroundColor: secondary.hover,
    },
    "&:focus:not(:hover)": {
      backgroundColor: secondary.focus,
    },
  },
  error: {
    backgroundColor: error.main,
    "&:hover": {
      backgroundColor: error.main,
    },
    "&:focus:not(:hover)": {
      backgroundColor: error.focus,
    },
  },

};

export default contained;
