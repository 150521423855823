import http from "../Common_service/rest_http_common";

class PreferenceService {
    get(id) {
        return http.get(`/Preferences/user/${id}`);
    }
    create(data) {
        return http.post(`/Preferences`, data);
    }
}

export default new PreferenceService();
