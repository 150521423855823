import { useState, useEffect } from "react";
// react-router components
import { useLocation, Link } from "react-router-dom";
// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";
// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import {
  Login
} from "@microsoft/mgt-react";
import { Hidden } from "@material-ui/core";
// Material Dashboard 2 React components
import MDBox from "App/Views/Components/Material_ui/MDBox";
import PersonIcon from '@mui/icons-material/Person';
import { Stack, Switch } from "@mui/material";
// Material Dashboard 2 React example components
import Breadcrumbs from "App/Views/Components/Material_ui/Breadcrumbs";
import NotificationItem from "App/Views/Components/Material_ui/NotificationItem";
// Custom styles for DashboardNavbar
import BugReportIcon from '@mui/icons-material/BugReport';
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarMobileMenu,
} from "App/Views/Components/Material_ui/DashboardNavbar/styles";
import {
  AgendaComp,
} from "../../Microsoft_MGT/MGTComponents";
// Material Dashboard 2 React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setOpenConfigurator,
  setDarkMode,

} from "context";
import MenuIcon from "@mui/icons-material/Menu";
import MDTypography from "../Typography";
import { PreferenceService, TokenService } from "services";

function DashboardNavbar({ absolute, light, isMini }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, openConfigurator, darkMode } = controller;
  const [openMenu, setOpenMenu] = useState(false);
  const route = useLocation().pathname.split("/").slice(1);

  useEffect(() => {


    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }
    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      const layout = document.getElementById("layout");

      setTransparentNavbar(dispatch, (fixedNavbar && layout.scrollTop === 0) || !fixedNavbar);
    }
    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    const layout = document.getElementById("layout");
    layout.addEventListener("scroll", handleTransparentNavbar);
    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();
    // Remove event listener on cleanup
    return () => {
      document.removeEventListener("scroll", handleTransparentNavbar)
    };
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator);
  // const handleOpenMenu = (event) => setOpenMenu(event.currentTarget);
  const handleCloseMenu = () => setOpenMenu(false);
  // Render the notifications menu
  // eslint-disable-next-line
  const renderMenu = () => (
    <Menu
      anchorEl={openMenu}
      anchorReference={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      open={Boolean(openMenu)}
      onClose={handleCloseMenu}
      sx={{ mt: 2 }}
    >
      <NotificationItem icon={<Icon>email</Icon>} title="Check new messages" />
      <NotificationItem icon={<Icon>podcasts</Icon>} title="Manage Podcast sessions" />
      <NotificationItem icon={<Icon>shopping_cart</Icon>} title="Payment successfully completed" />
    </Menu>
  );


  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;
      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }
      return colorValue;
    },
  });

  // Styles for the navbar icons
  const handleDarkMode = () => {
    const userID = TokenService.getUserID()
    const DarkMode = !darkMode
    const data = { User_UUID: userID, DarkMode: DarkMode ? 1 : 0 }
    const preference = PreferenceService.create(data)
    setDarkMode(dispatch, !darkMode)
  };


  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} />
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"}>

              <Stack direction="row" alignItems="center">
                <MDBox
                  display={{ xs: "none", md: "flex" }}
                  justifyContent='space-between'
                  alignItems='center'
                  lineHeight={1}
                >
                  <MDTypography variant='h6'>Light / Dark</MDTypography>
                  <Switch checked={darkMode} onChange={handleDarkMode} />
                </MDBox>
                {/* <AgendaComp></AgendaComp> */}

                {/* <IconButton
                  size="large"
                  id="myCustomTrigger"
                  disableRipple
                  color="inherit"
                  sx={{ navbarIconButton, display: { xs: "none", md: "inline-flex" }, px: "8px" }}
                >
                  <BugReportIcon size="large"></BugReportIcon>
                </IconButton> */}
                <Hidden smDown>
                  <Link to="/User/profile" >
                    <IconButton
                      size="large"
                      disableRipple
                      color="inherit"
                      sx={navbarIconButton}
                      onClick={handleConfiguratorOpen}
                    >
                      <PersonIcon sx={iconsStyle}>settings</PersonIcon>
                    </IconButton>
                  </Link></Hidden>
                <Hidden smDown>
                  <Login>
                  </Login>
                </Hidden>
                <IconButton
                  size="small"
                  disableRipple
                  color="inherit"
                  sx={{ navbarMobileMenu, display: { xs: "none", md: "inline-block", xl: "none" } }}
                  onClick={handleMiniSidenav}
                >
                  <MenuIcon sx={iconsStyle} fontSize="medium">
                    {miniSidenav ? "menu_open" : "menu"}
                  </MenuIcon>
                </IconButton>
              </Stack>
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
