import http from "../Common_service/rest_http_common";

class TaskDataService {
  async GetAllInTeam(param) {
    const response = await http.get(`/Task/team/${param}`);
    return response.data;
  }
  async getAll() {
    const response = await http.get(`/Task`);
    return response.data;
  }
  async getAllDeleted() {
    const response = await http.post(`/Task/deleted`);
    return response.data;
  }
  async restore(data) {
    const response = await http.post(`/Task/restore/${data}`);
    return response.data;
  }
  get(id) {
    return http.get(`/Task/${id}`);
  }
  create(data) {
    return http.post("/Task", data);
  }
  update(id, data) {
    return http.put(`/Task/${id}`, data);
  }
  delete(id) {
    return http.delete(`/Task/${id}`);
  }
}

export default new TaskDataService();
