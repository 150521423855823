import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import routes from "App/Views/routes";

import LogHoursInterface from "App/Views/Users/Hours/Log/logHoursInterface";
import YourSchedule from "App/Views/Users/Schedule/YourSchedule";
import { uniqueId } from "lodash";

import Box from '@mui/material/Box';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { Modal, Typography } from "@mui/material";

import CalendarViewMonthOutlinedIcon from '@mui/icons-material/CalendarViewMonthOutlined';
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddIcon from '@mui/icons-material/Add';


function MobileNav() {

    const [open2, setOpen2] = useState(false);
    const handleOpen2 = () => setOpen2(true);
    const handleClose2 = () => setOpen2(false);

    const [open3, setOpen3] = useState(false);
    const handleOpen3 = () => setOpen3(true);
    const handleClose3 = () => setOpen3(false);

    const [value, setValue] = useState("")

    const Navigate = useNavigate()

    const handleChange = (event, newValue) => {
        setValue(newValue)
        if (newValue === "Schedule") {

            console.log("Clicked schedule")
            handleOpen3()
        }
        if (newValue === "Log hours") {
            handleOpen2()
        }
        if (newValue === "Dashboard") {
            console.log("Clicked dashboard");

            Navigate("/Dashboard")
        }
        if (newValue === "Admins dashboard") {
            console.log("Clicked admin");

            Navigate("/Admins")
        }
    }

    const renderRoutes = routes.map((e, index) => {
        if (e.length === 1) {
            return e.map(
                ({ type, name, icon, title, noCollapse, key, href, route }) => {
                    if (type === "collapse") {
                        /* if (key === "logHours") {
                            return (
                                <BottomNavigationAction
                                    key={key + uniqueId() + index}
                                    icon={icon}
                                    label={<Typography sx={{ fontSize: 9, marginTop: 1 }}>{name}</Typography>}
                                    value={name}>
                                </BottomNavigationAction>
                            );
                            // return ;
                        } else  */if (key === "/Schedule") {
                            return (
                                <BottomNavigationAction
                                    key={key + uniqueId() + index}
                                    icon={icon}
                                    label={<Typography sx={{ fontSize: 9, marginTop: 1 }}>{name}</Typography>}
                                    value={name}>
                                </BottomNavigationAction>
                            );
                            // return ;
                        }
                        else if (key === "dashboard") {
                            return (
                                <BottomNavigationAction
                                    key={key + uniqueId() + index}
                                    icon={icon}
                                    label={<Typography sx={{ fontSize: 9, marginTop: 1 }}>{name}</Typography>}
                                    value={name}>
                                </BottomNavigationAction>
                            );
                            // return ;
                        }
                        else if (key === "/Admins") {
                            return (
                                <BottomNavigationAction
                                    key={key + uniqueId() + index}
                                    icon={icon}
                                    label={<Typography sx={{ fontSize: 9, marginTop: 1 }}>Admin</Typography>}
                                    value={name}>
                                </BottomNavigationAction>
                            );
                            // return ;
                        }
                    } else {
                        return null;
                    }
                }
            );
        }

    });
    return (
        <>
            {/*     <Modal
                key={uniqueId()}
                open={open2}
                onClose={handleClose2}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <LogHoursInterface
                    key={uniqueId()}
                    handleClose={handleClose2}
                ></LogHoursInterface>
            </Modal> */}
            <Modal
                key={uniqueId()}
                open={open3}
                onClose={handleClose3}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
                style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            >
                <YourSchedule
                    key={uniqueId()}
                    handleClose={handleClose3}
                ></YourSchedule>
            </Modal>
            <Box sx={{ width: "100vw", display: { xs: "block", md: "none" }, position: "fixed", bottom: "0", zIndex: "1000", pointerEvents: "none" }}>
                <BottomNavigation value={value} sx={{ pointerEvents: "all", alignItems: "center" }} onChange={handleChange} showLabels={true}>
                    {renderRoutes}
                    {/* <BottomNavigationAction
                        key={uniqueId()}
                        icon={<DashboardIcon sx={{ height: 20 }} />}
                        label={<Typography sx={{ fontSize: 9, marginTop: 1 }}>Dashboard</Typography>}
                        showLabel={true}
                    /> */}
                </BottomNavigation>
            </Box>
        </>
    )
}

export default MobileNav;