import { EmplyService, HoursDataService, LocationsDataService, ProjectDataService, SupplementDataService, TaskDataService, TeamsDataService } from "services";
import { createContext, useReducer, useEffect } from "react"
import salaryService from "services/REST_service/salary.service";
import ConverterClass from "App/Functions/converterClass.js";
import TokenService from "services/REST_service/token.service";
import ScheduleDataService from "services/REST_service/schedule.service";
import LogsDataService from "services/REST_service/logs.service";
import moment from "moment"
import { useDebouncedCallback } from "App/Functions/debounce";

const initialState = {}

const adminInitialState = {
  UserLogsSettings: {
    limit: 20,
    offset: 0,
    person: undefined,
    transaction: undefined,
  }
}

const getDaysArray = function (start, end) {
  for (
    var arr = [], dt = new Date(start);
    dt <= end;
    dt.setDate(dt.getDate() + 1)
  ) {
    arr.push(new Date(dt));
  }
  return arr;
};

moment.locale("en", { week: { dow: 1, doy: 4 } });
const thisMonday = moment().startOf("week");
const thisFriday = moment().startOf("week").add(4, "days");
const thisSunday = moment().endOf("week");
const workWeek = getDaysArray(thisMonday, thisFriday);

const initialOfficeSchedule = {
  SelectedDays: {
    Monday: thisMonday,
    Friday: thisFriday,
    Sunday: thisSunday,
    WeekDays: workWeek,
  },
  filterBy: {
    Team: undefined,
    Location: undefined,
    People: [],
  },
  People: undefined,
  RunTime: 0,
  Hours: undefined
}

export const GlobalState = createContext(initialState)
export const AdminGlobalState = createContext(adminInitialState)
export const OfficeScheduleState = createContext(initialOfficeSchedule)

export const StoreProvider = ({ children }) => {
  const Reducer = (state, action) => {
    const name = Object.keys(action);
    const values = Object.values(action);
    return { ...state, [name]: values[0] };
  };
  const [state, dispatch] = useReducer(Reducer, initialState);
  useEffect(() => {
    const fetchData = async () => {
      try {
        let CurrentPeriod
        try {
          CurrentPeriod = await salaryService.get_current_period();
          dispatch({ CurrentPeriod: CurrentPeriod.data[0] })
        } catch (error) {
          dispatch({
            Notification: {
              color: "error",
              icon: "warning",
              title: "Failed",
              content: "Failed to fetch current period",
              dateTime: moment(),
            },
          });
        }
        let role = TokenService.getRoles();
        if (typeof role === "string") {
          role = [role];
        }
        if (role.some((item) => ["admin"].includes(item))) {
          try {
            let Project_deleted = await ProjectDataService.getAllDeleted();
            dispatch({ Project_deleted })
          } catch (error) {
            dispatch({
              Notification: {
                color: "error",
                icon: "warning",
                title: "Failed",
                content: "Failed to fetch deleted projects",
                dateTime: moment(),
              },
            });
          }
          try {
            let Task_deleted = await TaskDataService.getAllDeleted();
            dispatch({ Task_deleted })
          } catch (error) {
            dispatch({
              Notification: {
                color: "error",
                icon: "warning",
                title: "Failed",
                content: "Failed to fetch deleted tasks",
                dateTime: moment(),
              },
            });
          }
        }
        try {
          const dep = await EmplyService.RetrieveDepartments();
          let Departments = await ConverterClass.ConvertDepartments(dep);
          dispatch({ Departments })
        } catch (error) {
          dispatch({
            Notification: {
              color: "error",
              icon: "warning",
              title: "Failed",
              content: "Failed to fetch departments",
              dateTime: moment(),
            },
          });
        }
        try {
          let Hours = await HoursDataService.RetrieveHours(CurrentPeriod.data[0]);
          // here
          dispatch({ Hours })
        } catch (error) {
          dispatch({
            Notification: {
              color: "error",
              icon: "warning",
              title: "Failed",
              content: "Failed to fetch hours",
              dateTime: moment(),
            },
          });
        }
        try {
          /// Here, if current period is undefined.
          let Schedules = await ScheduleDataService.RetrieveSchedules(CurrentPeriod.data[0])
          dispatch({ Schedules })
        } catch (error) {
          dispatch({
            Notification: {
              color: "error",
              icon: "warning",
              title: "Failed",
              content: "Error fetching data: ",
              dateTime: moment(),
            },
          });
        }
        try {
          let Teams = await TeamsDataService.getAll();
          dispatch({ Teams })
        } catch (error) {
          dispatch({
            Notification: {
              color: "error",
              icon: "warning",
              title: "Failed",
              content: "Failed to fetch teams",
              dateTime: moment(),
            },
          });
        }
        try {
          let Locations = await LocationsDataService.getAll();
          dispatch({ Locations })
        } catch (error) {
          dispatch({
            Notification: {
              color: "error",
              icon: "warning",
              title: "Failed",
              content: "Failed to fetch locations",
              dateTime: moment(),
            },
          });
        }
        try {
          let Type = await SupplementDataService.GetEverything();
          dispatch({ Type })
        } catch (error) {
          dispatch({
            Notification: {
              color: "error",
              icon: "warning",
              title: "Failed",
              content: "Failed to fetch supplements",
              dateTime: moment(),
            },
          });
        }
        try {
          let Project = await ProjectDataService.getAll();
          dispatch({ Project })
        } catch (error) {
          dispatch({
            Notification: {
              color: "error",
              icon: "warning",
              title: "Failed",
              content: "Failed to fetch projects",
              dateTime: moment(),
            },
          });
        }
        try {
          let Task = await TaskDataService.getAll();
          dispatch({ Task })
        } catch (error) {
          dispatch({
            Notification: {
              color: "error",
              icon: "warning",
              title: "Failed",
              content: "Failed to fetch tasks",
              dateTime: moment(),
            },
          });
        }
        try {
          let Roles = await TokenService.getRoles();
          dispatch({ Roles });
        } catch (error) {
          dispatch({
            Notification: {
              color: "error",
              icon: "warning",
              title: "Failed",
              content: "Failed to fetch roles",
              dateTime: moment(),
            },
          });
        }
        dispatch({ Fetched: true })
      }
      catch (error) {
        dispatch({
          Notification: {
            color: "error",
            icon: "warning",
            title: "Failed",
            content: "Error fetching data: ",
            dateTime: moment(),
          },
        });
      }

    };
    fetchData();
  }, []);
  return <GlobalState.Provider value={[state, dispatch]}>{state.Fetched && children} </GlobalState.Provider>;
}

export const AdminStoreProvider = ({ children }) => {
  const debounce = useDebouncedCallback((callback, value) => {
    callback(value);
  }, 1000);
  const Reducer = (state, action) => {
    const name = Object.keys(action);
    const values = Object.values(action);
    return { ...state, [name]: values[0] };
  };
  const [state, dispatch] = useReducer(Reducer, adminInitialState);

  useEffect(() => {
    const fetchData = async () => {
      const options = {
        offset: state.UserLogsSettings.offset,
        limit: state.UserLogsSettings.limit,
      };
      if (state.UserLogsSettings.person) {
        options.person = state.UserLogsSettings.person.id
      }
      if (state.UserLogsSettings.transaction) {
        options.transaction = state.UserLogsSettings.transaction
      }
      let UserLogs = await LogsDataService.getAllwithOptions(options);
      UserLogs = await UserLogs;
      dispatch({ UserLogs });
    };
    debounce(fetchData());
  }, [debounce, state.UserLogsSettings]);

  return <AdminGlobalState.Provider value={[state, dispatch]}>{children}</AdminGlobalState.Provider>;
}

export const OfficeScheduleStoreProvider = ({ children }) => {
  const ScheduleReducer = (state, action) => {
    const name = Object.keys(action);
    const values = Object.values(action);
    return { ...state, [name]: values[0] };
  }
  const [state, dispatch] = useReducer(ScheduleReducer, initialOfficeSchedule);
  return <OfficeScheduleState.Provider value={[state, dispatch]}>{children}</OfficeScheduleState.Provider>;
};


