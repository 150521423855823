import moment from 'moment-timezone'

class ConverterClass {
  ToDBDates(Date) {
    return moment(Date).tz("GMT").format();
  }
  formToJSON(elements) {
    const data = Convert(elements);
    return data;
  }
  toReadableDates(Date) {
    return moment(Date).format("MM-DD-YYYY");
  }
  UniqueArrayByKeyValue(array, key) {
    const unique = array.reduce(function (a, b) {
      function indexOfProperty(a, b) {
        for (var i = 0; i < a.length; i++) {
          if (a[i][key] === b[key]) {
            return i;
          }
        }
        return -1;
      }

      if (indexOfProperty(a, b) < 0) a.push(b);
      return a;
    }, []);
    return unique;
  }

  makeMomentDateWithTime(dateString, timeString) {
    const timeFormat = "HH:mm";

    let time = moment(timeString, timeFormat);
    let day = moment(dateString);
    day.set({
      hour: time.hour(),
      minute: time.minute(),
      second: time.second(),
    });

    return day;
  }

  currentMomentTimezone(date) {
    return moment.utc(date).tz(moment.tz.guess());
  }
  getCalendarDates(data) {
    let calendar = [];
    const startDay = moment(data).utc().clone().startOf("month").startOf("isoWeek");
    const endDay = moment(data).utc().clone().endOf("month").endOf("isoWeek");
    let date = startDay.clone().subtract(1, "day");
    while (date.isBefore(endDay, "day")) {
      calendar.push({
        days: Array(7)
          .fill(0)
          .map(() => date.add(1, "day").clone()),
      });
    }
    return calendar;
  }
  getCalendarDatesPassed(start, end) {
    let calendar = [];
    const startDay = moment(start).clone().startOf("day");
    const endDay = moment(end).clone().endOf("day");

    let date = startDay.clone().subtract(1, "day");

    while (date.isBefore(endDay, "day")) {
      calendar.push({
        days: Array(7)
          .fill(0)
          .map(() => date.add(1, "day").clone()),
      });
    }
    return calendar;
  }
  addWeekdays(date, days) {
    date = moment(date); // use a clone
    while (days > 0) {
      date = date.add(1, "days");
      // decrease "days" only if it's a weekday.
      if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
        days -= 1;
      }
    }
    return date;
  }
  dataURItoBlob(dataURI) {
    try {
      // convert base64 to raw binary data held in a string
      var byteString = atob(dataURI.split(",")[1]);
      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
      // write the bytes of the string to an ArrayBuffer
      var arrayBuffer = new ArrayBuffer(byteString.length);
      var _ia = new Uint8Array(arrayBuffer);
      for (var i = 0; i < byteString.length; i++) {
        _ia[i] = byteString.charCodeAt(i);
      }
      var dataView = new DataView(arrayBuffer);
      var blob = new Blob([dataView.buffer], { type: mimeString });
      return blob;
    } catch {
      return undefined;
    }
  }

  calDiffInPercentage = function (a, b) {
    return a < b ? "-" + ((b - a) * 100) / a : ((a - b) * 100) / b;
  };

  percentCalculation(a, b) {
    var c = (parseFloat(a) * parseFloat(b)) / 100;
    return parseFloat(c);
  }

  ConvertDepartments = async (dep) => {
    let departments = {};
    let index = 2;
    for (const e of await dep) {
      e.index = index++;
      e.SubDepartments = [];
      if (!e.parentId) {
        departments.HQ = e;
      }
    }
    for (const e of await dep) {
      if (e.parentId) {
        if (e.parentId === departments.HQ.id) {
          e.parentIndex = departments.HQ.index;
          departments.HQ.SubDepartments.push(e);
        }
      }
    }
    for (const e of await dep) {
      if (e.parentId) {
        if (e.parentId !== departments.HQ.id) {
          for (const t of departments.HQ.SubDepartments) {
            if (e.parentId === t.id) {
              e.parentIndex = t.index;
              t.SubDepartments.push(e);
            }
          }
        }
      }
    }
    for (const e of await dep) {
      if (e.SubDepartments.length === 0) {
        delete e.SubDepartments;
      }
    }
    return departments
  }

}
const isValidElement = (element) => {
  if (element.type === "radio") {
    if (!element.checked === false) {
      return element.name && element.value;
    }
  } else {
    if (element.name !== "") {
      return element.name && element.value;
    } else {
      return;
    }
  }
};



/// Dynamically get form elements name=value and turn it into JSON
const Convert = (elements) =>
  [].reduce.call(
    elements,
    (data, element) => {
      // Make sure the element has the required properties.
      if (isValidElement(element)) {
        data[element.name] = element.value;
      }
      return data;
    },
    {}



  );


export default new ConverterClass();

// const move = (arr, old_index, new_index) => {
//   while (old_index < 0) {
//     old_index += arr.length;
//   }
//   while (new_index < 0) {
//     new_index += arr.length;
//   }
//   if (new_index >= arr.length) {
//     var k = new_index - arr.length;
//     while (k-- + 1) {
//       arr.push(undefined);
//     }
//   }
//   arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
//   return arr;
// };

