import colors from "assets/theme-dark/base/colors";
import borders from "assets/theme/base/borders";
const { borderRadius } = borders;
const { dark } = colors;

const buttonBase = {
  defaultProps: {
    disableRipple: false,
  },
  styleOverrides: {
    root: {
      color: dark.main,
      borderRadius: borderRadius.xs,

    },
    borderRadius: borderRadius.xs,
  },
};

export default buttonBase;
