import { useEffect, useCallback, useContext, useState, memo } from "react";
import { EmplyService } from "services";
import { GlobalState } from "context/store.js";
import Card from "@mui/material/Card";
import CardHeader from '@mui/material/CardHeader';
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import moment from "moment";
import { FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";
function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}
function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}
function union(a, b) {
    return [...a, ...not(b, a)];
}
const MasterDataInterfaceComp = memo(({ right, setRight, left, setLeft }) => {
    const [Store, setStore] = useContext(GlobalState);
    const [checked, setChecked] = useState([]);
    const [defaultVal, setDefaultVal] = useState([])
    const [disabled, setDisabled] = useState([])

    const deepFind = useCallback((arr, search) => {
        for (var obj of Object.values(arr)) {
            if (search(obj)) {
                return obj;
            }
            if (obj.id) {
                var deepResult = deepFind(obj.id, search);
                if (deepResult) {
                    return deepResult;
                }
            }
        }
        return null;
    }, []);

    const confirmChanges = useCallback(
        async () => {
            let editable = []
            for (const r of right) {
                editable.push({ FormID: r, Disabled: disabled.includes(r) ? 1 : 0 })
            }
            try {
                await EmplyService.CreateEditable(editable)
                setStore({
                    Notification: {
                        color: "success",
                        icon: "check",
                        title: "Success",
                        content:
                            "Successfully updated form Id's",
                        dateTime: moment(),
                    },
                });
            } catch (error) {
                setStore({
                    Notification: {
                        color: "success",
                        icon: "check",
                        title: "Success",
                        content:
                            "Something went wrong",
                        dateTime: moment(),
                    },
                });
            }
        },
        [right, disabled, setStore],
    )

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);
    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };
    const numberOfChecked = (items) => intersection(checked, items).length;
    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };
    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };
    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };
    const handleCheckedDisabled = (event) => {
        const currentIndex = disabled.indexOf(event.target.value);
        const newChecked = [...disabled];
        if (currentIndex === -1) {
            newChecked.push(event.target.value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setDisabled(newChecked);
    }
    const customList = (title, items) => (
        <Card>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items?.length && items?.length !== 0}
                        indeterminate={
                            numberOfChecked(items) !== items?.length && numberOfChecked(items) !== 0
                        }
                        disabled={items?.length === 0}
                        inputProps={{
                            'aria-label': 'all items selected',
                        }}
                    />
                }
                title={title}
                subheader={`${numberOfChecked(items)}/${items?.length} selected`}
            />
            <Divider />
            <List
                sx={{
                    width: 500,
                    height: 400,
                    bgcolor: 'background.paper',
                    overflow: 'auto',
                }}
                dense
                component="div"
                role="list"
            >

                {items && items.map((value, index) => {
                    const label = defaultVal.find(object => object.id === value)?.label
                    const labelId = `transfer-list-all-item-${value}-label`;
                    return (
                        <ListItem
                            key={value}
                            role="listitem"
                            button
                            onClick={handleToggle(value)}
                            id={index}
                        >
                            <ListItemIcon>
                                <Checkbox
                                    checked={checked.indexOf(value) !== -1}
                                    tabIndex={-1}
                                    disableRipple
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </ListItemIcon>
                            <ListItemText id={labelId} primary={label} />
                        </ListItem>
                    );
                })}
                <ListItem />
            </List>
        </Card>
    );
    const getData = useCallback(
        async () => {
            const data = await EmplyService.GetMasterDataForm()
            let editable = await EmplyService.RetrieveEditable()
            let editableIds = editable.map((e) => e.FormID)
            setDisabled(editable.filter((e) => e.Disabled === 1).map((e) => e.FormID))
            if (Object.entries(data).length === 0) {
                return;
            }
            let defaultva = [];
            let leftVal = []
            let dataIds = data.dataTypes.map((e) => e.id)
            // if ids in editableIds and not in e then remove from editableIds
            editableIds = editableIds.filter((e) => dataIds.includes(e))
            for (const e of data.dataTypes) {
                let label = ''
                e.title.localization.forEach((element) => {
                    label += element.value + " | "
                })
                label = label.slice(0, -3)
                let id = e.id
                defaultva.push({ label: label, id: id });
                if (editableIds.includes(id)) {
                    continue
                }
                leftVal.push(id)

            }
            setRight(editableIds)
            setDefaultVal(defaultva)
            setLeft(leftVal);

        },
        [setLeft, setRight],
    )

    useEffect(() => {
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (

        <Card sx={{ paddingBottom: "2rem" }}>
            <CardHeader title="Master Data Interface" subheader="Choose which fields in the master data should be editable." />
            <Stack direction={{ md: "column", xxl: "row" }} spacing={4}>
                <Grid container spacing={2} alignItems='center' justifyContent='center' direction={{ md: "column", xl: "row" }} >
                    <Grid item>{customList("Not Visible", left)}</Grid>
                    <Grid item >
                        <Grid container direction='column' alignItems='center'>
                            <Button
                                sx={{ my: 0.5 }}
                                variant='outlined'
                                size='small'
                                onClick={handleCheckedRight}
                                disabled={leftChecked.length === 0}
                                aria-label='move selected right'
                            >
                                &gt;
                            </Button>
                            <Button
                                sx={{ my: 0.5 }}
                                variant='outlined'
                                size='small'
                                onClick={handleCheckedLeft}
                                disabled={rightChecked.length === 0}
                                aria-label='move selected left'
                            >
                                &lt;
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item>{customList("Visible", right)}</Grid>

                </Grid>

                <FormGroup sx={{ width: { md: "100%", xxl: "40%" } }}>
                    <Stack sx={{ width: "fit-content", margin: "auto" }}>
                        <Typography variant="h5" component='h5'>Select the fields which will be disabled</Typography>
                        <Divider />
                        {right && right.map((value, index) => {
                            return (<FormControlLabel key={index} control={<Checkbox onChange={handleCheckedDisabled} checked={disabled.includes(value)} value={value} />} label={defaultVal.find(object => object.id === value)?.label} />)
                        })}
                        <Button variant="contained" onClick={confirmChanges} sx={{ margin: "auto" }}>Confirm visible and editable form fields</Button>
                    </Stack>
                </FormGroup>
            </Stack>
        </Card>
    )
})
export default MasterDataInterfaceComp
